const BusinessData = { 

salesLetter : {
title:"Sales letter",  
caption:"Get an effective sales letter that will persuade your reader to take action.",
category:"business", 
inputs : 
`Company: [company name]
Invitation to purchase or try a product: [product/services]
Product description: [write description]
Cost: [write price]

Format:
Get the attention of the reader
Explain what you are selling
Convince the reader to buy your product
Include a call to action
close note`
},
inquiryLetter : {
title:"Inquiry letter",  
caption:"Help you write an inquiry letter that will get the information you need.",
category:"business", 
inputs : 
`To: [recipient name]
Product name: [name of the product]
Reason why: [write why you are interested]

Format:
Greetings
Mention the product you are inquiring about
Include the reason why you are interested in the product.
Mention any relevant experience or skills you have.
Thank the reader for their time`
},
complaintLetter : {
title:"Complaint letter",  
caption:"This AI template will help you write a complaint letter that will get results.",
category:"business", 
inputs : 
`Product name: [product name]
The date of purchase: [date of purchase]
The problem with the product: [reason]
The name and address of the company: [company details]
A copy of the receipt
The reference number of the complaint`
},
// apologyLetter : {
// title:"Apology letter",  
// caption:"This AI template can help you write an apology letter that will repair the damage.",
// category:"business", 
// inputs : 
// `To: [name of a person]
// State the reason for the mistake:[write reason briefly]

// Format:
// Sincerely apologize.
// Admit the mistake.
// Promise that it will not happen again in the future.
// Request for forgiveness.
// Thank the person for their understanding.`
// },
thankyouLetter : {
title:"Thankyou letter",  
caption:"Helps you write a thank you letter that will show your appreciation.",
category:"business", 
inputs : 
`To:[receipent name]
Your:[name]
Giving the reason for saying thanks:[write reason]

Format:
Greeting
Describe the reason for writing 
End with a professional sign-off`
},

requestLetter : {
title:"Request Letter",  
caption:"This AI template can help you write a request letter that will get results.",
category:"business", 
inputs : 
`To: [name of a person]
Your Name: [your name]
Request for: [write the reason]

Format:
Formal greeting
Describe the reason for writing
Thank the reader for their time and consideration`
},
adjustmentLetter : {
title:"Adjustment Letter",  
caption:"This helps you to write an adjustment letter that will get results",
category:"business", 
inputs : 
`To: [name of a person]
Your Name: [your name]
Reason for writing: [explain]

Format: 
Politely explain the situation
Request a specific adjustment
close note`
},
priceIncreaseLetter : {
title:"Price increase Letter",  
caption:"This template can help you write a price increase letter that will get results.",
category:"business", 
inputs : 
`Product or service name: [write product or service name]
Reason for the price increase: [write the reason for increase]
New price: [write new price]
Effective date: [new price effective date]
Any special offers: [discounts if any]
Contact person for information: [name]`
},
officeMemorandum : {
title:"Office memorandum",  
caption:"This AI template can help you write an office memorandum that will get results.",
category:"business", 
inputs : 
`Date: [date on which the memo was written]
Memo subject: [write subject]
Memo purpose: [write purpose]
Main points of the memo: [main points]
Contact persone: [name or department]
Memo written by: [name]`
},
welcomeLetter : {
title:"Welcome letter",  
caption:"Help's you to write a welcome letter that will make a good impression.",
category:"business", 
inputs : 
`Who you are: [write here..]
Purpose of the letter: [write here..]
Company overview: [write here..]
What reader can expect: [write here..]
A call to action`
},
acknowledgementLetter : {
title:"Acknowledgement Letter",  
caption:"Write an acknowledgement letter that will show your appreciation.",
category:"business", 
inputs : 
`To: [name]
State the purpose:[reason for being acknowledge and there achievements]
Express appreciation for the contribution
Express how his contribution helps the organization
Encouraging closing note`
},
circularLetter : {
title:"Circular Letter",  
caption:"This AI template can help you write a circular letter that will get results.",
category:"business", 
inputs : 
`Purpose of the letter:[write purpose]
Intended audience:[who is your audience]
Points to be covered:[main points]
Contact information: [persone name contact details]
A call to action, if appropriate
Date and contact information for the sender`
},
letterOfInterest : {
title:"Letter of interest",  
caption:"This AI template can help you write a letter of interest that will get results.",
category:"business", 
inputs : 
`To: [Recipient name]
Position interested in: [job title] 
Your qualifications: [write qualification]
Your qualifications: [write skills]
Your interest in the company: [company name]
Your availability for an interview: [yes]
close note`
},
demandOfDeliveryLetter : {
title:"Demand of delivery letter",  
caption:"Help's you to write a demand of delivery letter that will get results.",
category:"business", 
inputs : 
`To:[write here..]
Reason for wiriting: [in case request a delivery]
Specific items:[write item name]
Needed by:[data]
Closing note`
},
toWhomItMayConcern : {
title:"To whom it may concern",  
caption:"This AI template can help you write a letter that will get results.",
category:"business", 
inputs : 
`Start with: "TO WHOM IT MAY CONCERN"
Your Name: [your name]
Company: [company name]
Information about: [write here..]
Close formally`
},
aboutUs : {
title:"About us",
category:"business",
caption:"Help's you to write an about us page that will make a good impression.",
inputs :`Information:
Company: [name]
About company: [intro, history, vision, mission]
Product or services: [product or services]
Recognition: [awards or accolades]
Team: [employees or team members]
Contact: [contact info]`
}, 
}
export default BusinessData;