import {React, useState, useEffect, useContext} from "react";
import { Button, Nav, Navbar, NavDropdown, ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Route, useLocation, Link  } from "react-router-dom";
import { useUserAuth } from "../../../context/UserAuthContext";
import { SubscriberContext } from "../../../context/subscriberContext";  
import { FcGoogle} from "react-icons/fc";
import { RiFileList3Line, RiListCheck2} from "react-icons/ri";
import "./Header.css";
import { LogoSvg, LogOutIcon, PricingIcon, ProfileIcon, SupportIcon, SideArrow, UpgradeIcon, TemplateIcon, PicIcon, TempIcon, MyContentIcon, ImageGeneratorIcon  } from "../../../assets/svg/SvgIcons";
import Login from "../../Login";

const Header = () => {
  const { subscriber, setSubscriber } = useContext(SubscriberContext); 
  const [expanded, setExpanded] = useState(false);
  const { logOut, user } = useUserAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [usagePercentage, setUsagePercentage] = useState(0);
  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/login");
    } catch (error) {
      //console.log(error.message);
    }
  };

  
  

  useEffect(() => { 
    if(subscriber){
      const now = (subscriber.totalCredits / subscriber.creditsLimit) * 100; 
      setUsagePercentage(Math.round(now))
    }  
  }); 

  const handleSelect = (eventKey) => {
    // alert(`selected ${eventKey}`)
    navigate(`/${eventKey}`);
  };

  return (
    <> 
      <Navbar className="header-nav" onSelect={handleSelect} expanded={expanded} expand="lg">   
          <Navbar.Brand><Link to="/stock-image" className="logo"><LogoSvg/> </Link></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
              <Nav className="center-nav">   
                  <Nav.Link className="clrIcon" eventKey="stock-image" onClick={() => setExpanded(false)}><ImageGeneratorIcon/>Dashboard</Nav.Link> 
                  <Nav.Link className="clrIcon" eventKey="gallery" onClick={() => setExpanded(false)}><MyContentIcon/>Gallery</Nav.Link> 
                  <Nav.Link className="clrIcon" eventKey="my-creations" disabled={!user} onClick={() => setExpanded(false)}><MyContentIcon/>My Creations</Nav.Link> 
                </Nav> 
                
              <Nav className="myprofile-link">  
          
                <div className="wordsUsed">
                    <span> <i className="wIcon">Credits</i>{user && `${subscriber.totalCredits}`} {!user && <>100</>}</span>   
                </div>
              
              <NavDropdown 
                title={
                  <span className="profileImgWrap">
                    <span className="profile-img"> 
                    {user && user.photoURL &&  <img src={user.photoURL} alt="profile" width="100%" /> } 
                    {user && !user.photoURL &&  <PicIcon/>} 
                    {!user &&  <PicIcon/>} 
                    </span>
                    <span className="linkName">My Account</span>
                    </span>
                } 
                  align="end" id="basic-nav-dropdown">
                 {user && <NavDropdown.Item eventKey="profile" onClick={() => setExpanded(false)}><ProfileIcon/>My Profile</NavDropdown.Item>}
                  <NavDropdown.Item eventKey="pricing" onClick={() => setExpanded(false)}><PricingIcon/>Pricing Plans</NavDropdown.Item>
                  <NavDropdown.Item eventKey="" href="mailto:info.imagekraft@gmail.com" target="_blank" onClick={() => setExpanded(false)}><SupportIcon/>Email Support</NavDropdown.Item> 
                  {user && <NavDropdown.Item onClick={handleLogout}><LogOutIcon/> Log out</NavDropdown.Item>}
                  {!user && <NavDropdown.Item eventKey="login"><LogOutIcon/> LogIn</NavDropdown.Item>}
              </NavDropdown>
              </Nav> 
          </Navbar.Collapse> 
      </Navbar> 
                            {/* <div role="alert" className="fade alert alert-danger show text-center"> 
                            Sorry something went wrong... We're already working on the problem and expect to resolve it shortly. 
                            </div>  */}
                              
    {/* // <header>
    //     <Link to="/stock-image" className="logo"> </Link>
    //     <Nav activeKey="1" onSelect={handleSelect} className="float-right">
    //     <Nav.Item>
    //         <Nav.Link eventKey="dashboard" title="Dashboard">
    //         Dashboard
    //         </Nav.Link>
    //     </Nav.Item>
    //     <Nav.Item>
    //         <Nav.Link eventKey="projects" title="Projects">
    //         Projects
    //         </Nav.Link>
    //     </Nav.Item>
    //     <NavDropdown title="My Account" id="nav-dropdown">
    //         <NavDropdown.Item eventKey="profile" title="Profile">Profile</NavDropdown.Item>
    //         <NavDropdown.Item eventKey="contact-us" title="Contact us">Contact us</NavDropdown.Item>
    //         <NavDropdown.Item eventKey="support" title="Support">Support</NavDropdown.Item> 
    //         <NavDropdown.Item eventKey="faqs" title="FAQs">FAQs</NavDropdown.Item>
    //         <NavDropdown.Divider />
    //         <NavDropdown.Item onClick={handleLogout} title="Log out">Log out</NavDropdown.Item>
    //     </NavDropdown>
    //     </Nav>
    // </header>  */}
    </>
  );
};

export default Header;  