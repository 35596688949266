const EmailsData = { 

coldEmail : {
title:"Cold email",
category:"email",
caption:"This AI template can help you write a cold email to your potential customers.",
inputs :`To: [recipient name]
From: [sender name]
Company name: [company name]
Subject: [subject]
Reason for reaching out: [reason for reach out]
close note `
},
followUpEmail : {
title:"Follow up email",
category:"email",
caption:"This AI template can help you write a follow up email to your potential customers.",
inputs :`To: [recipient name]
From: [sender name]
Company name: [company name] 
Reason for reaching out: [reason for reach out]
close note
Sing off with contact info`
},
statusUpdateEmail : {
title:"Status update email",
caption:"This AI template can help you write a status update email to manager.",
category:"email",
inputs :`To: [recipient name]
From: [sender name]
Current status:  [write current status]
Challenges:  [write challenges]

Format:
State your purpose for writing 
Give an update on your current status 
Conclude with a call to action or next steps `
},
requestingEmail : {
title:"Requesting email",
caption:"This AI template can help you write a requesting email for any purpous.",
category:"email",
inputs :`To: [recipient name]
From: [sender name]
Requesting: [what you are requesting]
Why you are requesting: [reason]`
},
feedbackEmail : {
title:"Feedback email",
caption:"This AI template can help you write a feedback email to your employees.",
category:"email",
inputs :`To: [recipient name] 
Areas to improve: [write here..]
Steps to take for improvement: [write here..]

Format:
Start with positive aspects of the recipient's performance
The areas where the recipient can improve
The support the sender is willing to provide`
},
reportingEmail : {
title:"Reporting email",
caption:"This AI template can help you write a reporting email to your boss.",
category:"email",
inputs :`To: [recipient name]
Want to communicate: [write here..]
A call to action: [any action recipient to do after reading this email]`
},
leaveEmail : {
title:"Leave email",
caption:"This AI template can help you write a leave email to your boss.",
category:"email",
inputs :`To: [recipient name]
Reason for the leave:[write here..]
Dates of the leave:[write here..]`
},
sickLeaveEmail : {
title:"Sick leave email",
caption:"This AI template can help you write a sick leave email to your boss.",
category:"email",
inputs :`To: [recipient name]
Reason for the leave:[write here..]
Dates of the leave:[write here..]`
}, 
workFromHomeRequestEmail : {
title:"Work from home request email",
caption:"This AI template can help you write a work from home request email to your boss.",
category:"email",
inputs :`To: [recipient name]
Reason:[write here..]
Dates[write here..]`
}, 
resignationLetter : {
title:"Resignation letter",
caption:"This AI template can help you write a resignation letter to your employer.",
category:"email",
inputs : 
`Information:
To: [name of a person]
Company: [company name]
Current position: [current job title]
Date of Resignation: [date of resignation]
Name: [your name]`
},
referenceLetter : {
title:"Reference letter",
category:"email",
caption:"This AI template can help you write a impressive reference letter to manager.",
inputs :`To: [recipient name]
From: [sender name]
Date: [date] 
Subject: [letter subject]
Reference name: [individual name]
Relation with referrer: [write corporate relation]
How he fits the job: [write about the person and his skills] 

Format: 
Greeting
Introduce yourself and your relationship to the other person
Explain the person's nature, skills and achievements
Explain why the fit the role
close note`
},
salesEmail : {
title:"Sales email",
caption:"This AI template can help you write a sales email to your potential customers.",
category:"email",
inputs :`To: [recipient name]
Company Name: [your company name]
Products or Services: [write the product or services you want to sale]
Reasons to choose: [what are the benefits or features of the product or services] 
Your Name: [your name]

Format:
Greetings,
Thank you for your interest
Write a reason to choose Product or services
Closing note
Sincerely`
}, 
replyEmail : {
title:"Reply email",
caption:"This AI template can help you write the best convincing reply email.",
category:"email",
inputs :`[Add the email data to generate reply email]`
},
introductionEmail : {
title:"Introduction email",
caption:"Help's you to write an crisp and clear introduction email.",
category:"email",
inputs :`To: [recipient name] 
State the purpose: [write here..]
About you: [your information] `
},
testimonialEmail : {
title:"Testimonial email",
caption:"Help's you to write a testimonial email for your clients.",
category:"email",
inputs :`Person's name:[write here..]
How you know: [write here..]
What you admire: [write here..]
Specific example:[write here..]
Overall opinion:[write here..]`
},
milestoneEmail : {
title:"Milestone email",
caption:"Help's you to write the best achivment milestone email.",
category:"email",
inputs :`When the milestone was hit:[write here..]
What the milestone was:[write here..]
How the milestone was hit:[write here..]
What the company plans to do next:[write here..]`
},
generalEmail : {
title:"General email",
caption:"This AI template can help you write a general email.",
category:"email",
inputs :`To: [recipient name]
From: [sender name]
About you: [your information]
Subject: [subject]
Reason for reaching out: [reason for reach out]
close note `
},
newsLetterEmail : {
title:"Newsletter email",
caption:"AI helps you to write an informative newsletter email",
category:"email",
inputs :`purpose of the newsletter:[write here..]`
},
specialOfferEmail : {
title:"Special offer email",
caption:"Help's you to write a special offer email to your customers.",
category:"email",
inputs :`Offer itself: [what are you offering, and why is it special?]
Time frame: [when is the offer valid?]
Terms and conditions: [what are the rules of the offer?]
How to take advantage of the offer: {what do people need to do to get the discount or benefit?}
Why the offer is worth taking: [what are the benefits of taking advantage of the offer?]`
}, 
reviewRequestEmail : {
title:"Review request email",
caption:"Help's you to write a convincing review request email to team.",
category:"email",
inputs :`To: [recipient name]
From: [sender name]
Review request for : [your information]`
},
welcomeEmail : {
title:"Welcome email",
caption:"This AI template can help you write a welcome email to your customers.",
category:"email",
inputs :`To: [recipient name]
 Purpose of the email:[write here..]`
},
curatedContentEmail : {
title:"Curated content email",
caption:"Write a the best curated content email to your potential clients.",
category:"email",
inputs :`Target audience: [write here..]
Their interests: [write here..]
What content will resonate: [write here..]
How can you add value:[write here..]`
},
newProdudtAnnouncementEmail : {
title:"New product announcement email",
caption:"AI writes a new product announcement email to your potential customers.",
category:"email",
inputs :`To: [recipient name]
From: [sender name]
About you: [your information]
Subject: [subject]
Reason for reaching out: [reason for reach out]
close note `
},
abondonedCartEmail : {
title:"Abandoned cart email",
caption:"AI template can help you write an abandoned cart email.",
category:"email",
inputs :`To: [recipient name]
Product:[write here..]
Product description:[write here..]
Price:[write here..]

Format:
Write a catchy subject line
Briefly describe the new product
Include a link to learn more or purchase
Describe a call-to-action
Describe an element of urgency
Describe a giveaway or contest
`
},
progressEmail : {
title:"Progress email",
caption:"This AI template can help you write a progress email to client.",
category:"email",
inputs :`To: [recipient name]
Task you are working on : [write here..]
Progress on task:[write here..]
Any challenges:[write here..]
Plan for moving forward:[write here..]`
},
confirmationEmail : {
title:"Confirmation email",
caption:"This AI template can help you write a confirmation email to customers.",
category:"email",
inputs :`To: [recipient name]
From: [sender name]
Reason:[write here..]`
},
appreciationEmail : {
title:"Appreciation email",
caption:"AI template can help you write an appreciation email to team members.",
category:"email",
inputs :`To: [recipient name]
What you appreciate about the person: [write here..]
How the person has helped you or others: [write here..]
The impact of the person's actions: [write here..]`
} 
}
export default EmailsData;