import React from "react";  
import { Container, Button} from "react-bootstrap";  
import { useNavigate } from "react-router";
 
const NotFound = () => {     
  const navigate = useNavigate(); 

  return (
    <>  
      <Container>
      <div className="page-wrap d-flex flex-row align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center mt-5">
                        <span className="display-1 d-block mt-5">404</span>
                        <div className="mb-4 lead">The page you are looking for was not found.</div>
                        <Button variant="outline-primary mt-4" size="md" onClick={()=> navigate(`/tools`)}>Go to Tools Page</Button>
           
                    </div>
                </div>
            </div>
        </div> 
      </Container> 
    </>
  );
};

export default NotFound;