import {React, useState} from "react"; 
import { useNavigate } from "react-router";
import { NavLink } from 'react-router-dom'; 
import { Button, Card, Form, Container, Row, Col, Accordion } from "react-bootstrap";
import { AnimeIcon, AppIconsIcon, ArchitectureIcon, BlogBannerIcon, FashionIcon, IllustrationIcon, InteriorDesignIcon, PosterIcon, SideArrow, StockImageIcon, WallpaperIcon, WebUIIcon  } from "../../../assets/svg/SvgIcons";


const Sidebar = () => {  
  const navigate = useNavigate(); 
  const [isActive, setIsActive] = useState(false);
  const activeSidebar = () => {
    setIsActive(!isActive);
  }
  return (
   <>
   <Button variant="secondary" className="catButton" onClick={activeSidebar}><SideArrow/> Select Category</Button>
   <div className={`side-bar-nav column ${isActive ? 'activeSide' : ''}`}>
     <h2>Categories</h2>   
        <ul>
          <li>
          <NavLink  to="/stock-image" className={({isActive}) => (isActive ? "active" : 'none')}>
                <div className="svgIcon"> 
                  <StockImageIcon/>
                </div>
                <div className="linkName">
                  Stock Image
                </div>
            </NavLink>
          </li> 
          <li>
          <NavLink  to="/wallpaper" className={({isActive}) => (isActive ? "active" : 'none')}>
                <div className="svgIcon"> 
                <WallpaperIcon/>
                </div>
                <div className="linkName">
                   Wallpaper
                </div>
            </NavLink>
          </li>
          <li>
          <NavLink  to="/blog-banner" className={({isActive}) => (isActive ? "active" : 'none')}>
                <div className="svgIcon">  
                <BlogBannerIcon/>
                </div>
                <div className="linkName">
                   Blog Banner
                </div>
            </NavLink>
          </li>
        {/* <li>
          <NavLink to="/book-cover" className={({isActive}) => (isActive ? "active" : 'none')}>
                <div className="svgIcon"> 
                  <BookCoverIcon/>
                </div>
                <div className="linkName">
                   Book Cover
                </div>
            </NavLink>
          </li>  */}
          <li>
          <NavLink to="/illustration" className={({isActive}) => (isActive ? "active" : 'none')}>
                <div className="svgIcon"> 
                  <IllustrationIcon/>
                </div>
                <div className="linkName">
                Illustration
                </div>
            </NavLink>
          </li>
          <li>
          <NavLink to="/poster" className={({isActive}) => (isActive ? "active" : 'none')}>
                <div className="svgIcon"> 
                 <PosterIcon/>
                </div>
                <div className="linkName">
              Poster
                </div>
            </NavLink>
          </li> 
          <li>
          <NavLink to="/web-ui" className={({isActive}) => (isActive ? "active" : 'none')}>
                <div className="svgIcon"> 
                 <WebUIIcon/>
                </div>
                <div className="linkName">
                Web UI
                </div>
            </NavLink>
          </li>
          <li>
          <NavLink to="/app-icon" className={({isActive}) => (isActive ? "active" : 'none')}>
                <div className="svgIcon"> 
                  <AppIconsIcon/>
                </div>
                <div className="linkName">
                   App Icons
                </div>
            </NavLink>
          </li>
          <li>
          <NavLink to="/architecture" className={({isActive}) => (isActive ? "active" : 'none')}>
                <div className="svgIcon"> 
                <ArchitectureIcon/>
                </div>
                <div className="linkName">
                Architecture
                </div>
            </NavLink>
          </li>
          <li>
          <NavLink to="/interior-design" className={({isActive}) => (isActive ? "active" : 'none')}>
                <div className="svgIcon"> 
                 <InteriorDesignIcon/>
                </div>
                <div className="linkName">
                Interior Design
                </div>
            </NavLink>
          </li>
          <li>
          <NavLink to="/fashion" className={({isActive}) => (isActive ? "active" : 'none')}>
                <div className="svgIcon"> 
                 <FashionIcon/>
                </div>
                <div className="linkName">
                Fashion
                </div>
            </NavLink>
          </li>
          <li>
          <NavLink to="/anime" className={({isActive}) => (isActive ? "active" : 'none')}>
                <div className="svgIcon"> 
                 <AnimeIcon/>
                </div>
                <div className="linkName">
                Anime
                </div>
            </NavLink>
          </li>
        </ul>    
         </div>  
        </>
  );
}; 
export default Sidebar;