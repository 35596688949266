import {React, useState, useEffect, useContext, useRef} from "react";
import { Button, Card, Form, Container, Row, Col, Accordion } from "react-bootstrap";
import { useNavigate } from "react-router"; 
import { useUserAuth } from "../../../context/UserAuthContext";
import { SubscriberContext } from "../../../context/subscriberContext";   
import { RiDeleteBinLine, RiFileCopy2Line, RiSaveLine, RiFileAddLine, RiSaveFill} from "react-icons/ri";
import Loader from "../../shared/utilities/loader/Loader";
import ContentEditable from 'react-contenteditable';
import { useParams, Link  } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'; 
import toast, { Toaster } from 'react-hot-toast';
import CommonDataServices from "../../../services/common.services";
import ImageCreatorDataService from "./services/ImageCreator.services"; 
import ImageCreatorAIServices from "./services/ImageCreatorAI.services" 
import { AppIconsIcon, ArticleIcon, BookCoverIcon, ContentRewriteIcon, DownloadIcon, AIIcon, IllustrationIcon, ImageCreatorIcon, ImageCreatorIcon1, PosterIcon, ScaleIcon, SideArrow, StockImageIcon, UpgradeIcon, FashionIcon, WebUIIcon  } from "../../../assets/svg/SvgIcons";
import UsageBadge from "../../shared/utilities/usage-badge/UsageBadge";
import { getFunctions, httpsCallable } from "firebase/functions"; 
import Dashboard from "../../dashboard/Dashboard";import Sidebar from "../../dashboard/sidebar/sidebar";
import InlineLogin from "../../InlineLogin";


const Fashion = () => { 
  const { user } = useUserAuth();
  //if(user){ localStorage.setItem("user", user.uid)} 
  const navigate = useNavigate();  
  const functions = getFunctions(); 
  const params = useParams();
  const [data, setData] = useState(null);
  const [queryObj, setQueryObj] = useState(null);  
  const [mainParam, setMainParam] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [copied, setCopied] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [projectId, setProjectId] = useState(null);
  const [files, setFiles] = useState(1);
  const [editMode, setEditMode] = useState(false) 
  const [updating, setUpdating] = useState(false) 
  const [activeSection, setActiveSection] = useState(null);
  const [tockenWords, setTockenWords] = useState(1); 
  const [formValue, setFormValue] = useState({prompt:"concept fashion design of a night party elegant luxury dress design by balenciaga, laagam, loewe, aimeleondore, with clear face structure, ultra realistic"});

  const {subscriber, setSubscriber} = useContext(SubscriberContext);
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const [modalData, setModalData] = useState({})
  const handleClose = () => setShowModal(false);
  const [sideNav, setSideNav] = useState(false);
  
  const [imageUrl, setImageUrl] = useState("https://pub-8b49af329fae499aa563997f5d4068a4.r2.dev/generations/ededef19-2624-4033-bf0e-ebbec5a5a4d8-0.png");
  const [rangeValue, setRangeValue] = useState(10);
  const [rangeSteps, setRangeSteps] = useState(10);

  const [selectModel, setSelectModel] = useState(false);
    const [upgradeModel, setUpgradeModel] = useState(false); 
  const [loginState, setloginState] = useState(false); 
  const [myImageModel, setMyImageModel] = useState(null);
  const [scale, setScale] = useState(1); 
 const [clockEta, setClockEta] = useState(null)
  const [countdown, setCountdown] = useState(0);
  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);
    if (countdown === 0) {
      clearInterval(countdownInterval);
    }
    return () => clearInterval(countdownInterval);
  }, [countdown]);
  useEffect(() => {
    document.body.style.overflow = 'hidden'; // hide scrollbar on mount 
    return () => {
      document.body.style.overflow = 'unset'; // restore scrollbar on unmount
    };
  }, []);
 const handleRangeChange = (event) => {
    setRangeValue(event.target.value);
  }
  const handleRangeStepsChange = (event) => {
    setRangeSteps(event.target.value);
  }
  const [checked, setChecked] = useState(false);

  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
  }
  const [selectedValue, setSelectedValue] = useState('dreamlike');
  const elementRef = useRef(null);
  const handleRadioChange = (e) => {
    setSelectedValue(e.target.value);
    setShowModal(false)
    setSelectModel(false)
  }
  const aiBoard = useRef(null); 
  const scollDown = () => {
    aiBoard.current.scrollTo(0, aiBoard.current.scrollHeight);
  }
  const updateSubscriber = async () =>{
    const subData = await CommonDataServices.getUsageLimit(user.uid);
    if(subData.data()){
      //console.log(subData.data())
      setSubscriber(subData.data())
    }
  }

 useEffect(() => {
 //console.log(user)
    if(params.prompt){
      console.log(params.prompt);
      setFormValue({prompt:params.prompt})  
    } 
   },[])

 useEffect(() => {
    setTockenWords(tockenWords)
    //console.log(tockenWords)
  },[tockenWords])  
   
const MAX_SCALE = 2.0; // maximum allowed scale value
  const MIN_SCALE = 0.5; // minimum allowed scale value
  const zoomPercentage = Math.round(scale * 100);
  const handleWheel = (event) => {
    event.preventDefault();
    const delta = event.deltaY;
    const factor = 0.1;
    setScale((prevScale) => {
      if (delta < 0) {
        const newScale = prevScale + factor;
        return newScale > MAX_SCALE ? MAX_SCALE : newScale; // limit to max scale
      } else {
        const newScale = prevScale - factor;
        return newScale < MIN_SCALE ? MIN_SCALE : newScale; // limit to min scale
      }
    });
  };
  useEffect(() => {
    const element = elementRef.current;
    element.addEventListener("wheel", handleWheel);
    return () => {
      element.removeEventListener("wheel", handleWheel);
    };
  }, []);
  const handleMinusClick = () => {
    setScale((prevScale) =>
      prevScale - 0.1 < MIN_SCALE ? MIN_SCALE : prevScale - 0.1
    );
  };
  const handlePlusClick = () => {
    setScale((prevScale) =>
      prevScale + 0.1 > MAX_SCALE ? MAX_SCALE : prevScale + 0.1
    );
  };

   
  const getProjectContent = async (conId) => {
    setLoading(true)
    setEditMode(true)
    const contentData = await ImageCreatorDataService.getAllProjectContent(user.uid, conId);
    const prjID = contentData.docs[0].data().projectId;
    setData(contentData.docs.map(doc => {
      // //console.log(doc);
      // //console.log(doc.data());
        return{
          id:doc.id,
          projectId: doc.data().projectId,
          text: doc.data().text,
          status: true 
        }
    }))  
    if(prjID){  
        //console.log(prjID)
        const getProjectDetails = await ImageCreatorDataService.getProjectData(user.uid, prjID); 
        //console.log(getProjectDetails.data()) 
        const dataObj = getProjectDetails.data();  
        setQueryObj({
          productName: dataObj.title,
          variant: 2,
          type: dataObj.type,
          files: 3,
          productFeatures: "",
          creationDate: dataObj.creationDate
        })
    }  
    setLoading(false); 
  };  

const [isShown, setIsShown] = useState(false);  
  const isAuthenticated = () => {
    if(user){
      return true;
    } else {
      setloginState(true)
      setShowModal(true)
      setSelectModel(false)
      return false;
    } 
  }
  const onFormSubmit = async (e) => { 
     e.preventDefault();
    if (isAuthenticated()) {
      setIsShown(true);
     const currentDate = Math.floor(Date.now() / 1000) 
      if(subscriber.totalCredits >= subscriber.creditsLimit || subscriber.premiumState === false || currentDate > subscriber.planEndDate){   
        setUpgradeModel(true)
      setShowModal(true)
      return;
     }
     setLoading(true)

     const formData = new FormData(e.target);
     const formDataObj = Object.fromEntries(formData.entries());
     setQueryObj(formDataObj); 
     //console.log(formDataObj) parseInt(paramConfig.variantVal)

    //Default image model is standard diffusion
    //formDataObj.imageModel
 
    let imageWidth = "512";
    let imageHeight = "768";

    console.log(formDataObj.imageSize)

    if(formDataObj.imageSize === "Square"){
      imageWidth = "768";
      imageHeight = "768";
    } 
    if (formDataObj.imageSize === "Horizontal"){
      imageWidth = "768";
      imageHeight = "512";
    } 
    if (formDataObj.imageSize === "Vertical"){
      imageWidth = "512";
      imageHeight = "768";
    }
 
     const paramData = {
        model_id: selectedValue,
        prompt:formDataObj.imagePrompt,
        negative_prompt:formDataObj.imageNegativePrompt ? formDataObj.imageNegativePrompt: "painting, extra fingers, mutated hands, poorly drawn hands, poorly drawn face, deformed, ugly, blurry, bad anatomy, bad proportions, extra limbs, cloned face, skinny, glitchy, double torso, extra arms, extra hands, mangled fingers, missing lips, ugly face, distorted face, extra legs, anime",
        width: imageWidth,
        height: imageHeight,
        samples: parseInt(formDataObj.imageSampler) ? formDataObj.imageSampler: 1,
        num_inference_steps: rangeSteps,
        seed: formDataObj.imageSeed ? formDataObj.imageSeed: null,
        guidance_scale: rangeValue ? rangeValue: 7.5, 
        enhance_prompt: "yes",
        webhook: null,
        track_id: null 
    };  
     //console.log(paramData)
     setMainParam(paramData)  
  try { 
                setLoading(true)   
                let imageKraftApi = await httpsCallable(functions, 'imageKraft2Api'); 
              //Changing the API
              if(paramData.model_id == "stable-diffusion"){
                imageKraftApi = await httpsCallable(functions, 'imageKraft1Api');
              } else{
                imageKraftApi = await httpsCallable(functions, 'imageKraft2Api');
              }
                imageKraftApi(paramData).then((response) => {   
                 //console.log(response)     
                 if(response){ 
                     if(response.data.data.status == "failed"){
                    //console.log(response.data.data.messege) 
                    // toast.error(response.data.data.messege)
                  }
                  if(response.data.success){ 
                    if (response.data.data.status === 'processing') {
                      setLoading(true)   
                      let etaTime = response.data.data.eta + 10; 
                      setClockEta(`Server is currently busy, estimated wait: `)
                      setCountdown(etaTime.toFixed())
                      toast.success(response.data.data.messege + " " + " ETA: " + response.data.data.eta)
                      const fetID = response.data.data.id
                      setTimeout(() => { 
                      callApi(fetID);  
                      }, response.data.data.eta * 1000); 
                    } else if (response.data.data.status === "success"){
                      setImageUrl(response.data.data.output[0]) 
                      setLoading(false)  
                      setClockEta(null)
                      let imgCredit = 1;
                      CommonDataServices.usageTrackingData(user.uid, imgCredit).then(() => {
                        updateSubscriber();
                      });
//add image to history
                    try{
                      let imgObj = {...response.data.data,  category:"fashion", publish:false, public:true}
                       CommonDataServices.addHistory(user.uid, imgObj).then((data) => {  
                       //console.log(data)
                      });    
                     } catch (err) { 
                      console.log(err.message); 
                    }
                    } else{
                        toast.error("Request failed, Try again later")
                        setLoading(false)
                    }
                  }
                 }                
                })   
          } catch (error) {
            console.log(error); 
            toast.error("Request failed, Try again later")
            setLoading(false)
          }; 

    //  try {
    //   const getAIdata = await httpsCallable(functions, 'wordkraftAI'); 
    //   const param = {
    //     type:"ImageCreator",  
    //     about: formDataObj.productFeatures,         
    //     language: formDataObj.language ? formDataObj.language: "English",
    //     tone: formDataObj.tone ? formDataObj.tone : "formal", 
    //     wcount:350,
    //     creative:parseFloat(paramConfig.creativeVal),
    //     num:parseInt(paramConfig.variantVal)
    //   }
    //   console.log(param)
    //   getAIdata(param).then((response) => {   
    //     console.log(response.data.choices[0].text);
    //     console.log(response); 
    //     CommonDataServices.usageTrackingData(user.uid, response).then(() => {
    //       updateSubscriber();
    //     });
    //     let respChoices = response.data.choices;
    //     respChoices.forEach(function (item, index) {
    //       let respText = response.data.choices[index].text;
    //       respText = respText.replace(/(\r\n|\n|\r){2}/,"")
    //       response.data.choices[index].text = respText
    //     });
    //     setData(response.data.choices);  
    //     setLoading(false)
    //   }); 
    // } catch (error) {
    //   console.log(error.message);
    //   setLoading(false) 
    // } 
} 
 }; 


  const handleLoginSuccess = (user) => { 
    setloginState(false)
    setShowModal(false) 
    console.log('Login successful:');
  };
  const callApi = async (fetID) => {
    try {
      setLoading(true) 
        const fetchID = fetID
        const imageKraftFetchApi = await httpsCallable(functions, 'imageKraftFetchApi');
        imageKraftFetchApi(fetchID).then((response) => {   
        //console.log(response)
        if (response.data.data.status === 'processing') {
          setTimeout(() => { 
            callApi(fetchID);  
            }, 5000); 
         } else if (response.data.data.status === "success"){
          setImageUrl(response.data.data.output[0]) 
          setLoading(false)  
          setClockEta(null)
          let imgCredit = 1;
              CommonDataServices.usageTrackingData(user.uid, imgCredit).then(() => {
                 updateSubscriber();
          });
//add image to history
                          try{
                                let imgObj = {...response.data.data,  category:"fashion", publish:false, public:true}
                                CommonDataServices.addHistory(user.uid, imgObj).then((data) => {  
                                //console.log(data)
                                });    
                            } catch (err) { 
                               console.log(err.message); 
                          }
        } else{
            toast.error("Request failed, Try again later")
            setLoading(false)
            setClockEta(null)
        }
      });
     } catch (error) {
        console.log(error); 
        toast.error("Request failed, Try again later")
        setLoading(false)
    };
  }

  const selectImageModel = () => {
    setShowModal(true)
    setMyImageModel();
    setSelectModel(true);
  }

  useEffect(() => {
    setData(data) 
    wordCounter();   
  }, [data])

  const wordCounter = () => {  
    if(data){
      data.map((item, i) => {
        let count = item.text.split(' ').filter(word => word !== '').length; 
        data[i].count = count; 
      }) 
    } 
    setData(data) 
   }
   const handleDownload = () => {
    console.log(imageUrl)
    window.open(imageUrl, '_blank');         
  };

const [autoLoader, setAutoLoader] = useState(false);
    const onAutoPrompt = async (e) => { 
    if (isAuthenticated()) {      
       const imgPrompt = {
          prompt: formValue.prompt,
          tokens: 100,
          cat: 'fashion'
       };
       if(formValue.prompt.length < 5){
        toast.error("Please enter prompt keywords")
        return;
       }
      //  console.log(formValue.prompt);
       try{
        const imagePrompt = await httpsCallable(functions, 'imageGeneratorPrompt');
        setAutoLoader(true)
        imagePrompt(imgPrompt).then((response) => { 
            let copyText = response.data.choices[0].text.replace(/\n/g, '').replace((/<br\s*[\/]?>/gi), "\n").replace(/&nbsp;/g, ' ');    
            setFormValue({...formValue, prompt: copyText})
            setAutoLoader(false)
        });
       }catch{
        setAutoLoader(false)
       }
      }
  }; 
  const [activeSize, setActiveSize] = useState("Vertical");
  const handleImgSize = (e) => {
    console.log(e.target.value)
    setActiveSize(e.target.value)
  };
  return (
    <> 
        <Container className={`columns mobileView ${sideNav ? "" : "justify"}`}>
        <Sidebar/>
      
      <div className="main-layout column">  
          <section className="tool-panel row">
          <Col className={`ai-board outputCol col m-0 p-0 ${editMode ? "col-md-12" : "col-md-8"}`}>  
                  <section className="tool-header">
                  <div className="text-left">
                    <Button variant="secondary" className="dwnBtn m-0 mr-3" type="submit" 
                                  disabled={loading && `disabled`} onClick={handleDownload}>
                                    <DownloadIcon/>  <span>Download</span>
                                  </Button>  
                                  <Button variant="secondary" className="m-0 upscaleBtn" type="submit" 
                                  disabled={loading && `disabled`}>
                                    <ScaleIcon/>  Upscale
                                  </Button> 
                    </div>
                  {clockEta &&  <div className="clockEta text-left float-left">
                       <span>{clockEta} {countdown} {` seconds`}</span> <span className="imgloader"></span> 
                    </div>}
                    <div className="imgZoom text-left"> 
                      <button onClick={handleMinusClick}>-</button>
                      <p>{zoomPercentage}%</p>
                      <button onClick={handlePlusClick}>+</button>
                    </div> 
                          
                            </section> 
                    <Card className="ai-board-body canvas" ref={aiBoard}> 
                           {loading &&<div className="genMoreLoad"> <Loader /> </div> }  

                           <div className="newImage" ref={elementRef} style={{ transform: `scale(${scale})` }}> 
                           { imageUrl && <img src={imageUrl} alt="Generated image" /> }  
                         </div>

                         
                      </Card>  
                  </Col>
               
                    <Col className="col-md-4 m-0 p-0 inputCol">  
                         <section className="tool-header">
      <div>
      <div className="svgIcon"> 
        <FashionIcon/>
        </div>
       <div>
       <h2>Fashion</h2> 
       <p>Let's create stunning visuals</p>
       </div>
<Button className="showPromptBtn" variant="link" onClick={() => setIsShown(!isShown)}>&or;</Button>
       </div> 
          </section> 
                    <Card className={`inputSection ${isShown ? 'cardHide' : 'cardShow'}`}>   
                      {/* <Card.Header>
                      <h2>Content Rewriter</h2> 
                      </Card.Header> */}
                          <Card.Body>  
                          {/* <Card.Title><FcViewDetails /> Product description</Card.Title> */}
                              <Form onSubmit={onFormSubmit}> 
                                  <Form.Group className="mb-3" controlId="imagePrompt">
                                  <Form.Label>Type a detailed prompt</Form.Label>
<Button  className="float-right autoPrompt" disabled={(autoLoader || loading)  && `disabled`} variant="secondary-outline" onClick={onAutoPrompt} name="autoPrompt" size="sm">
                                    <AIIcon/> Auto prompt</Button> 
                                      <Form.Control
                                      as="textarea"
                                      maxLength="600"
                                      placeholder="Your prompt"
                                      style={{ height: '120px' }}
                                      name="imagePrompt" 
                                      value={formValue.prompt}
                                      required
                                      onChange={((e) => {setFormValue({...formValue, prompt: e.target.value})})}
                                      /> 
                                  </Form.Group> 
      
                                  <Form.Group className="mb-3">
                                  <Form.Switch
                                      id="custom-switch"
                                      label="Negative prompt"
                                      className="negativePrompt"
                                      name="imagePrompt"
                                      checked={checked}
                                      onChange={handleSwitchChange}
                                    /> 
                                    {checked && 
                                      <Form.Control
                                      as="textarea"
                                      maxLength="600"
                                      placeholder="Items you don't want in the image"  
                                      style={{ height: '100px' }}
                                      name="imageNegativePrompt"
                                      /> }
                                  </Form.Group> 
                                  <Form.Group className="mt-3 mb-3 row">
                                    <Col className="col-12">
                                        <Form.Label>Image Size</Form.Label>    
                                     <div className="imageSizeRadio">
                                    <Form.Check
                                          type="radio"
                                          label="Square"
                                          id="option1"
                                          name="imageSize"
                                          value="Square"
                                          inline
                                          bsPrefix="custom-radio custom-control" 
                                          onChange={handleImgSize}
                                          className={activeSize == "Square" ? "active" : ""}
                                        />
                                        <Form.Check
                                          type="radio"
                                          label="Horizontal"
                                          id="option2"
                                          name="imageSize"
                                          value="Horizontal"
                                          inline
                                          bsPrefix="custom-radio custom-control" 
                                          onChange={handleImgSize}
                                          className={activeSize == "Horizontal" ? "active" : ""}
                                        />
                                        <Form.Check
                                          type="radio"
                                          label="Vertical"
                                          id="option3"
                                          name="imageSize"
                                          value="Vertical"
                                          inline
                                          bsPrefix="custom-radio custom-control" 
                                          onChange={handleImgSize}
                                          className={activeSize == "Vertical" ? "active" : ""}
                                        /> 
                                    </div>
                                    </Col>   
                                    {/* <Col className="col-12 mt-3">  
                                       <Form.Label>Select Model</Form.Label>
                                       <Button  className="selModel w-100" variant="secondary" name="imageModel" size="md" onClick={selectImageModel} value={selectedValue}>{selectedValue}</Button>
                                   </Col>  */}
                                  </Form.Group> 
                   
                                  
                                  <Button variant="primary" className="mt-3 mb-3 w-100" type="submit" 
                                  disabled={loading && `disabled`}>
                                      Generate
                                  </Button> 

                                  <div className="advanceOptions">
                                  <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Advanced Settings</Accordion.Header>
        <Accordion.Body>
        <Form.Group className="mt-3 mb-3 row">
                                    <Col className="col-12 mb-3">
                                        <Form.Label>Guidance Scale <span className="rangeValue">{rangeValue}</span></Form.Label>    
                                        <Form.Range min={1} max={20} value={rangeValue} onChange={handleRangeChange} />
                                        <Form.Text muted>Any number between 1 and 20. The guidance scale adjusts how much the image looks closer to the prompt(description). </Form.Text>
                                    </Col> 
                                    <Col className="col-12 mb-3">
                                        <Form.Label>Steps <span className="rangeSteps">{rangeSteps}</span></Form.Label>     
                                        <Form.Range min={1} max={50} value={rangeSteps} onChange={handleRangeStepsChange} />
                                        <Form.Text muted>  Number of denoising steps (minimum: 1; maximum: 50)</Form.Text>
                                    </Col> 
                                    <Col className="col-12 mb-3">   
                                        <Form.Label htmlFor="imageSeed">Seed</Form.Label>
                                        <Form.Control
                                          type="number"
                                          id="imageSeed"
                                          name="imageSeed" 
                                          aria-describedby="imageSeed"
                                        />
                                        <Form.Text muted>
                                        Leave it blank to use random value. By setting seed value (any number) you can generate reproducible image. Seed can be any random number.
                                        </Form.Text>
                                    </Col>
                                    {/* <Col className="col-12 mb-3">   
                                        <Form.Label htmlFor="imageSteps">Steps</Form.Label>
                                        <Form.Control
                                          type="number"
                                          id="imageSteps"
                                          name="imageSteps" 
                                          aria-describedby="imageSteps"
                                        />
                                        <Form.Text muted>
                                        Number of denoising steps (minimum: 1; maximum: 50)
                                        </Form.Text>
                                    </Col> */}

                                    <Col className="col-12 mb-3">
                                        <Form.Label>Sampler</Form.Label>    
                                        <Form.Select name="imageSampler" id="imageSampler" className="form-control">  
                                          <option value="k_euler_ancestral">k_euler_ancestral</option>
                                          <option value="none">None</option>
                                          <option value="ddim">ddim</option>
                                          <option value="plms">plms</option>
                                          <option value="k_euler">k_euler</option>
                                          <option value="k_euler_ancestral">k_euler_ancestral</option>
                                          <option value="k_heun">k_heun</option>
                                          <option value="k_dpm_2">k_dpm_2</option>
                                          <option value="k_dpm_2_ancestral">k_dpm_2_ancestral</option>
                                          <option value="k_dpmpp_2s_ancestral">k_dpmpp_2s_ancestral</option>
                                          <option value="k_lms">k_lms</option>
                                          <option value="k_dpmpp_2n">k_dpmpp_2n</option>
                                      </Form.Select>
                                    </Col> 
                                  </Form.Group> 
                            
          </Accordion.Body>
          </Accordion.Item>
          </Accordion>
          </div> 

                                  </Form>
                                  {/* <pre>{ JSON.stringify(formValue.name.length, undefined, 2)}</pre>       */}
                            </Card.Body>  
                      </Card>
                    </Col>
                   
              
              
          </section>
        </div>
      </Container>
      <Toaster position="top-center" reverseOrder={false} />


     
       <Modal show={showModal} onHide={handleClose} keyboard={false} className={selectModel ? "imageSelectionModal" : "" || loginState ? "loginState" : "" || upgradeModel ? "upgradeModal": ""}>
          <div>{selectModel}</div>
          {loginState && !upgradeModel &&  !selectModel && <Modal.Body className="text-center">  
             <InlineLogin onLoginSuccess={handleLoginSuccess}/>
          </Modal.Body>}
         {upgradeModel &&
           <Modal.Body className="text-center">
            <div className="upgradeIcon"><UpgradeIcon/></div>
            <h4>Upgrade your account</h4>
            <p>You have either exceeded the credit limit or your plan has expired. <br/>Please upgrade your account.</p>
            <Button className="upgradeBtn" variant="primary" size="md" onClick={()=> navigate(`/pricing`)}>Upgrade</Button>
          </Modal.Body>
         }
         {selectModel &&
         <Modal.Body className="text-center">
         <h4>Choose a model</h4>
              <div className="modelSelection"><Form>
                <Form.Check type="radio" className="analog" label="Analog Diffusion" name="Analog Diffusion" id="analog-diffusion" value="analog-diffusion" checked={selectedValue === 'analog-diffusion'} onChange={handleRadioChange} />
                <Form.Check type="radio" className="cyberpunk" label="Anime Diffusion" name="Anime Diffusion" id="cyberpunk-anime-diff" value="cyberpunk-anime-diff" checked={selectedValue === 'cyberpunk-anime-diff'} onChange={handleRadioChange} />
                <Form.Check type="radio" className="disco" label="Disco Diffusion" name="Disco Diffusion" id="disco-diffusion" value="disco-diffusion" checked={selectedValue === 'disco-diffusion'} onChange={handleRadioChange} />
                <Form.Check type="radio" className="dreamlike" label="Real Photo" name="Real Photo" id="dreamlike" value="dreamlike" checked={selectedValue === 'dreamlike'} onChange={handleRadioChange} />
                <Form.Check type="radio" className="portrait" label="Portrait Plus" name="Portrait+" id="Portrait Plus" value="portraitplus-diffusion" checked={selectedValue === 'portraitplus-diffusion'} onChange={handleRadioChange} />
                <Form.Check type="radio" className="redshift" label="(3d) Redshift Diffusion" name="(3d) Redshift Diffusion" id="redshift-diffusion" value="redshift-diffusion" checked={selectedValue === 'redshift-diffusion'} onChange={handleRadioChange} />
                <Form.Check type="radio" className="stable" label="Stable Diffusion" name="Stable Diffusion" id="stable-diffusion" value="stable-diffusion" checked={selectedValue === 'stable-diffusion'} onChange={handleRadioChange} />
                <Form.Check type="radio" className="mo-di" label="Mo-Di" name="Mo-Di" id="mo-di-diffusion" value="mo-di-diffusion" checked={selectedValue === 'mo-di-diffusion'} onChange={handleRadioChange} />
              </Form>
            </div>
            </Modal.Body>}
         </Modal> 

    </>
  );
};

export default Fashion;



