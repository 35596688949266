import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import { useParams } from "react-router-dom";
const ProtectedRoute = ({ children }) => {
  const { user } = useUserAuth();
  const params = useParams();

  //console.log("Check user in Private: ", user);
  if (!user?.emailVerified) { 
    let loginUrl = '/login'
    if(params){
      loginUrl = `/login/${params.type}`;
    }
    return <Navigate to={loginUrl} />;
  }
  return children;
};

export default ProtectedRoute;