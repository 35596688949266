
import {React, useState, useEffect, useContext, useRef} from "react"; 
import { Button, Card, Form, Container, Row, Col, Accordion } from "react-bootstrap";
import "./Gallery.css";  
import { useUserAuth } from "../../../context/UserAuthContext";
import CommonDataServices from "../../../services/common.services";
import { DeleteIcon, DownloadIcon, HideIcon, ShowIcon } from "../../../assets/svg/SvgIcons";
import Loader from "../../shared/utilities/loader/Loader";
import toast, { Toaster } from 'react-hot-toast';

const Gallery = () => { 
  const { user } = useUserAuth();
  const [historyData, setHistoryData] = useState();
  const [noResults, setNoResults] = useState(false);
  const [lastDoc, setLastDoc] = useState(null);
  const [loadMore, setLoadMore] = useState(false);
  const [admin, setAdmin] = useState(false);
  useEffect(() => { 
    getGallery();  
    return () => {
      // Unsubscribe or cleanup logic here
    };
   },[]) 
   useEffect(() => { 
    if(user){
        if(user.uid == "h42sWF7W4Xb8FQGTzUDHkUKTlEa2"){
            setAdmin(true)
        }
    } 
   },[admin]) 
 
   
  const getGallery = async () =>{   
    try{ 
      await CommonDataServices.getGallery(user.uid, lastDoc).then((data) => {  
             setLoadMore(false)
             if(data){  
              setLastDoc(data.lastDoc);
             }
              if(data.docs.length > 0){ 
                data.docs.map((doc, index) => {   
                 let docData = doc.data();
                 docData.docId = doc.id; 
                 if(user.uid != "h42sWF7W4Xb8FQGTzUDHkUKTlEa2"){ 
                    if(docData.publish === true){
                        setHistoryData(prevState => {
                            if (Array.isArray(prevState)) { 
                              return [...prevState, docData]; 
                            } else { 
                              return [docData];
                            }
                          });
                    } 
                 } 
                 if(user.uid == "h42sWF7W4Xb8FQGTzUDHkUKTlEa2"){
                    setHistoryData(prevState => {
                        if (Array.isArray(prevState)) { 
                          return [...prevState, docData]; 
                        } else { 
                          return [docData];
                        }
                      });
                 } 
                }); 
            } else { 
              console.log("no results")
                   setNoResults(true);
                   setLoadMore(false) 
            } 
        });   
      } catch (err) { 
      console.log(err.message); 
    } 
  }
  const handleLoadMore = () => {
  //  console.log(lastDoc);
    setLoadMore(true)
    getGallery();
  }
  const handleDownload = (e) => { 
    window.open(e.target.name, '_blank');         
  };
  const handlePublish = async (e) => {  
     let docId = e.target.name;  
     let publishState = true;
        try { 
            await CommonDataServices.setPublishImg(docId, publishState).then((data) => { 
                toast.success("Published!!")
            }) 
        } catch (err) { 
            console.log(err.message); 
        } 
  };
  const handleRemovePublish = async (e) => { 
    let docId = e.target.name;  
    let publishState = false;
       try { 
           await CommonDataServices.setPublishImg(docId, publishState).then((data) => { 
            toast.success("Published removed!!")
           }) 
       } catch (err) { 
           console.log(err.message); 
       } 
 };
 const handleDeleteImg = async (e) => { 
    let docId = e.target.name; 
       try { 
           await CommonDataServices.setDeleteImg(docId).then((data) => { 
             getGallery();
             toast.success("Image deleted!!")
           }) 
       } catch (err) { 
           console.log(err.message); 
       } 
 };
    return ( 
     <>
     <div className="header-txt text-center"><h2>Community Gallery</h2></div>
        {!historyData && !noResults && <Loader/>}
        <div className="images-wrapper"> 
        {!admin &&
               <>
                    {historyData && historyData.map((dataItem, index) => ( 
                        <figure className="image-item" key={index}>
                            <img src={dataItem.output[0]} className="generated-image"/>
                                    <figcaption>
                                        <Button variant="link" className="img-download" type="submit" 
                                            onClick={handleDownload} name={dataItem.output[0]}>
                                                 <DownloadIcon /> Download                                            
                                            </Button> 
                                </figcaption>
                        </figure> 
                    ))}
               </>
             }
           { admin &&
               <>
                      {historyData && historyData.map((dataItem, index) => ( 
                        <figure className="image-item" key={index}>
                            <img src={dataItem.output[0]} className="generated-image"/>
                                    <figcaption>
                                        <Button variant="link" className="img-download" type="submit" 
                                            onClick={handleDownload} name={dataItem.output[0]}>
                                                <DownloadIcon /> Download             
                                            </Button>
                                        <Button variant="link" className="img-download" type="submit" 
                                            onClick={handleDeleteImg} name={dataItem.docId} >
                                                  <DeleteIcon/> Delete                                   
                                            </Button>    
                                        {!dataItem.publish &&
                                        <Button variant="link" className="img-download" type="submit" name={dataItem.docId} 
                                        onClick={handlePublish}>
                                            {/* <DownloadIcon />  */}
                                           <HideIcon/> Show                          
                                        </Button>
                                        }    
                                        {dataItem.publish &&
                                        <Button variant="link" className="img-download" type="submit" name={dataItem.docId} 
                                        onClick={handleRemovePublish}>
                                            {/* <DownloadIcon />  */}
                                            <ShowIcon/> Hide                                  
                                        </Button>
                                        } 
                                </figcaption>
                        </figure> 
                    ))}
               </>
            }
        </div>        
        {noResults && <div className="mx-auto my-5 text-center w-100 no-results">No images found!</div>}
        {loadMore && <Loader/>}
        {lastDoc &&
          <div className="loadMoreCol col text-center my-5">
          <Button variant="secondary" onClick={handleLoadMore}>Load More..</Button>
       </div>}
       <Toaster position="top-center" reverseOrder={false} />
     </>
    );  
};

export default Gallery;