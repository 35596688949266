import {React, useState, useEffect, useContext, useRef} from "react";
import { Button, Card, Form, Container, Row, Col, Accordion } from "react-bootstrap";
import { useNavigate } from "react-router"; 
import { useUserAuth } from "../../../context/UserAuthContext";
import { SubscriberContext } from "../../../context/subscriberContext";   
import { RiDeleteBinLine, RiFileCopy2Line, RiSaveLine, RiFileAddLine, RiSaveFill} from "react-icons/ri";
import Loader from "../../shared/utilities/loader/Loader";
import ContentEditable from 'react-contenteditable';
import { useParams, Link  } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'; 
import toast, { Toaster } from 'react-hot-toast';
import CommonDataServices from "../../../services/common.services";
import ImageCreatorDataService from "./services/ImageCreator.services"; 
import ImageCreatorAIServices from "./services/ImageCreatorAI.services"
import "./ImageCreator.css";  
import { AppIconsIcon, ArticleIcon, BookCoverIcon, ContentRewriteIcon, DownloadIcon, AIIcon, IllustrationIcon, ImageCreatorIcon, ImageCreatorIcon1, PosterIcon, ScaleIcon, SideArrow, StockImageIcon, UpgradeIcon, WallpaperIcon, WebUIIcon  } from "../../../assets/svg/SvgIcons";
import UsageBadge from "../../shared/utilities/usage-badge/UsageBadge";
import { getFunctions, httpsCallable } from "firebase/functions"; 
import Dashboard from "../../dashboard/Dashboard";import Sidebar from "../../dashboard/sidebar/sidebar";
import InlineLogin from "../../InlineLogin";


const ImageCreator = () => { 
  const { user } = useUserAuth();
  //if(user){ localStorage.setItem("user", user.uid)} 
  const navigate = useNavigate();  
  const functions = getFunctions(); 
  const params = useParams();
  const [data, setData] = useState(null);
  const [queryObj, setQueryObj] = useState(null);  
  const [mainParam, setMainParam] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [copied, setCopied] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [projectId, setProjectId] = useState(null);
  const [files, setFiles] = useState(1);
  const [editMode, setEditMode] = useState(false) 
  const [updating, setUpdating] = useState(false) 
  const [activeSection, setActiveSection] = useState(null);
  const [tockenWords, setTockenWords] = useState(1); 
  const [formValue, setFormValue] = useState({name:'', benefits:''});

  const {subscriber, setSubscriber} = useContext(SubscriberContext);
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const [modalData, setModalData] = useState({})
  const handleClose = () => setShowModal(false);
  const [sideNav, setSideNav] = useState(false);
  
  const [imageUrl, setImageUrl] = useState("https://pub-8b49af329fae499aa563997f5d4068a4.r2.dev/generations/a19b0dd6-3ea6-4c65-9d8c-309ef2cf2873-0.png");
  const [rangeValue, setRangeValue] = useState(7);
  const [rangeSteps, setRangeSteps] = useState(10);

  const [selectModel, setSelectModel] = useState(false);
    const [upgradeModel, setUpgradeModel] = useState(false); 
  const [loginState, setloginState] = useState(false); 
  const [myImageModel, setMyImageModel] = useState(null);
  const [scale, setScale] = useState(1); 
 const [clockEta, setClockEta] = useState(null)
  const [countdown, setCountdown] = useState(0);
  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);
    if (countdown === 0) {
      clearInterval(countdownInterval);
    }
    return () => clearInterval(countdownInterval);
  }, [countdown]);
  useEffect(() => {
    document.body.style.overflow = 'hidden'; // hide scrollbar on mount 
    return () => {
      document.body.style.overflow = 'unset'; // restore scrollbar on unmount
    };
  }, []);
 const handleRangeChange = (event) => {
    setRangeValue(event.target.value);
  }
  const handleRangeStepsChange = (event) => {
    setRangeSteps(event.target.value);
  }
  const [checked, setChecked] = useState(false);

  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
  }
  const [selectedValue, setSelectedValue] = useState('stable-diffu');
  const elementRef = useRef(null);
  const handleRadioChange = (e) => {
    setSelectedValue(e.target.value);
    setShowModal(false)
    setSelectModel(false)
  }
  const aiBoard = useRef(null); 
  const scollDown = () => {
    aiBoard.current.scrollTo(0, aiBoard.current.scrollHeight);
  }
  const updateSubscriber = async () =>{
    const subData = await CommonDataServices.getUsageLimit(user.uid);
    if(subData.data()){
      //console.log(subData.data())
      setSubscriber(subData.data())
    }
  }

 useEffect(() => {
 //console.log(user)
    if(params.prompt){
      console.log(params.prompt);
      setFormValue({prompt:params.prompt})  
    } 
   },[])

 useEffect(() => {
    setTockenWords(tockenWords)
    //console.log(tockenWords)
  },[tockenWords])  
   
const MAX_SCALE = 2.0; // maximum allowed scale value
  const MIN_SCALE = 0.5; // minimum allowed scale value
  const zoomPercentage = Math.round(scale * 100);
  const handleWheel = (event) => {
    event.preventDefault();
    const delta = event.deltaY;
    const factor = 0.1;
    setScale((prevScale) => {
      if (delta < 0) {
        const newScale = prevScale + factor;
        return newScale > MAX_SCALE ? MAX_SCALE : newScale; // limit to max scale
      } else {
        const newScale = prevScale - factor;
        return newScale < MIN_SCALE ? MIN_SCALE : newScale; // limit to min scale
      }
    });
  };
  useEffect(() => {
    const element = elementRef.current;
    element.addEventListener("wheel", handleWheel);
    return () => {
      element.removeEventListener("wheel", handleWheel);
    };
  }, []);
  const handleMinusClick = () => {
    setScale((prevScale) =>
      prevScale - 0.1 < MIN_SCALE ? MIN_SCALE : prevScale - 0.1
    );
  };
  const handlePlusClick = () => {
    setScale((prevScale) =>
      prevScale + 0.1 > MAX_SCALE ? MAX_SCALE : prevScale + 0.1
    );
  };

   
  const getProjectContent = async (conId) => {
    setLoading(true)
    setEditMode(true)
    const contentData = await ImageCreatorDataService.getAllProjectContent(user.uid, conId);
    const prjID = contentData.docs[0].data().projectId;
    setData(contentData.docs.map(doc => {
      // //console.log(doc);
      // //console.log(doc.data());
        return{
          id:doc.id,
          projectId: doc.data().projectId,
          text: doc.data().text,
          status: true 
        }
    }))  
    if(prjID){  
        //console.log(prjID)
        const getProjectDetails = await ImageCreatorDataService.getProjectData(user.uid, prjID); 
        //console.log(getProjectDetails.data()) 
        const dataObj = getProjectDetails.data();  
        setQueryObj({
          productName: dataObj.title,
          variant: 2,
          type: dataObj.type,
          files: 3,
          productFeatures: "",
          creationDate: dataObj.creationDate
        })
    }  
    setLoading(false); 
  };  

const [isShown, setIsShown] = useState(false);  
  const isAuthenticated = () => {
    if(user){
      return true;
    } else {
      setloginState(true)
      setShowModal(true)
      setSelectModel(false)
      return false;
    } 
  }
  const onFormSubmit = async (e) => { 
     e.preventDefault();
    if (isAuthenticated()) {
      setIsShown(true);
     const currentDate = Math.floor(Date.now() / 1000) 
      if(subscriber.totalCredits >= subscriber.creditsLimit || subscriber.premiumState === false || currentDate > subscriber.planEndDate){   
        setUpgradeModel(true)
      setShowModal(true)
      return;
     }
     setLoading(true)

     const formData = new FormData(e.target);
     const formDataObj = Object.fromEntries(formData.entries());
     setQueryObj(formDataObj); 
     //console.log(formDataObj) parseInt(paramConfig.variantVal)

    //Default image model is standard diffusion
    //formDataObj.imageModel
 
    let imageWidth = "768";
    let imageHeight = "768";

    console.log(formDataObj.imageSize)

    if(formDataObj.imageSize === "Square"){
      imageWidth = "768";
      imageHeight = "768";
    } 
    if (formDataObj.imageSize === "Horizontal"){
      imageWidth = "768";
      imageHeight = "512";
    } 
    if (formDataObj.imageSize === "Vertical"){
      imageWidth = "512";
      imageHeight = "768";
    }
 
     const paramData = {
        model_id: selectedValue,
        prompt:formDataObj.imagePrompt,
        negative_prompt:formDataObj.imageNegativePrompt ? formDataObj.imageNegativePrompt: null,
        width: imageWidth,
        height: imageHeight,
        samples: parseInt(formDataObj.imageSampler) ? formDataObj.imageSampler: 1,
        num_inference_steps: rangeSteps,
        seed: formDataObj.imageSeed ? formDataObj.imageSeed: null,
        guidance_scale: rangeValue ? rangeValue: 7.5, 
        enhance_prompt: "yes",
        webhook: null,
        track_id: null 
    };  
     //console.log(paramData)
     setMainParam(paramData)  
  try { 
                setLoading(true)   
                let imageKraftApi = await httpsCallable(functions, 'imageKraft2Api'); 
              //Changing the API
              if(paramData.model_id == "stable-diffusion"){
                imageKraftApi = await httpsCallable(functions, 'imageKraft1Api');
              } else{
                imageKraftApi = await httpsCallable(functions, 'imageKraft2Api');
              }
                imageKraftApi(paramData).then((response) => {   
                 //console.log(response)     
                 if(response){ 
                     if(response.data.data.status == "failed"){
                    //console.log(response.data.data.messege) 
                    // toast.error(response.data.data.messege)
                  }
                  if(response.data.success){ 
                    if (response.data.data.status === 'processing') {
                      setLoading(true)   
                      let etaTime = response.data.data.eta + 10; 
                      setClockEta(`Server is currently busy, estimated wait: `)
                      setCountdown(etaTime.toFixed())
                      toast.success(response.data.data.messege + " " + " ETA: " + response.data.data.eta)
                      const fetID = response.data.data.id
                      setTimeout(() => { 
                      callApi(fetID);  
                      }, response.data.data.eta * 1000); 
                    } else if (response.data.data.status === "success"){
                      setImageUrl(response.data.data.output[0]) 
                      setLoading(false)  
                      setClockEta(null)
                      let imgCredit = 1;
                      CommonDataServices.usageTrackingData(user.uid, imgCredit).then(() => {
                        updateSubscriber();
                      });
//add image to history
                    try{
                      let imgObj = {...response.data.data,  category:"wallpaper", publish:false, public:true}
                       CommonDataServices.addHistory(user.uid, imgObj).then((data) => {  
                       //console.log(data)
                      });    
                     } catch (err) { 
                      console.log(err.message); 
                    }
                    } else{
                        toast.error("Request failed, Try again later")
                        setLoading(false)
                    }
                  }
                 }                
                })   
          } catch (error) {
            console.log(error); 
            toast.error("Request failed, Try again later")
            setLoading(false)
          }; 

    //  try {
    //   const getAIdata = await httpsCallable(functions, 'wordkraftAI'); 
    //   const param = {
    //     type:"ImageCreator",  
    //     about: formDataObj.productFeatures,         
    //     language: formDataObj.language ? formDataObj.language: "English",
    //     tone: formDataObj.tone ? formDataObj.tone : "formal", 
    //     wcount:350,
    //     creative:parseFloat(paramConfig.creativeVal),
    //     num:parseInt(paramConfig.variantVal)
    //   }
    //   console.log(param)
    //   getAIdata(param).then((response) => {   
    //     console.log(response.data.choices[0].text);
    //     console.log(response); 
    //     CommonDataServices.usageTrackingData(user.uid, response).then(() => {
    //       updateSubscriber();
    //     });
    //     let respChoices = response.data.choices;
    //     respChoices.forEach(function (item, index) {
    //       let respText = response.data.choices[index].text;
    //       respText = respText.replace(/(\r\n|\n|\r){2}/,"")
    //       response.data.choices[index].text = respText
    //     });
    //     setData(response.data.choices);  
    //     setLoading(false)
    //   }); 
    // } catch (error) {
    //   console.log(error.message);
    //   setLoading(false) 
    // } 
} 
 }; 


  const handleLoginSuccess = (user) => { 
    setloginState(false)
    setShowModal(false) 
    console.log('Login successful:');
  };
  const callApi = async (fetID) => {
    try {
      setLoading(true) 
        const fetchID = fetID
        const imageKraftFetchApi = await httpsCallable(functions, 'imageKraftFetchApi');
        imageKraftFetchApi(fetchID).then((response) => {   
        //console.log(response)
        if (response.data.data.status === 'processing') {
          setTimeout(() => { 
            callApi(fetchID);  
            }, 5000); 
         } else if (response.data.data.status === "success"){
          setImageUrl(response.data.data.output[0]) 
          setLoading(false)  
          setClockEta(null)
          let imgCredit = 1;
              CommonDataServices.usageTrackingData(user.uid, imgCredit).then(() => {
                 updateSubscriber();
          });
//add image to history
                          try{
                                let imgObj = {...response.data.data,  category:"wallpaper", publish:false, public:true}
                                CommonDataServices.addHistory(user.uid, imgObj).then((data) => {  
                                //console.log(data)
                                });    
                            } catch (err) { 
                               console.log(err.message); 
                          }
        } else{
            toast.error("Request failed, Try again later")
            setLoading(false)
            setClockEta(null)
        }
      });
     } catch (error) {
        console.log(error); 
        toast.error("Request failed, Try again later")
        setLoading(false)
    };
  }

  const selectImageModel = () => {
    setShowModal(true)
    setMyImageModel();
    setSelectModel(true);
  }

  useEffect(() => {
    setData(data) 
    wordCounter();   
  }, [data])

  const wordCounter = () => {  
    if(data){
      data.map((item, i) => {
        let count = item.text.split(' ').filter(word => word !== '').length; 
        data[i].count = count; 
      }) 
    } 
    setData(data) 
   }
   const handleDownload = () => {
    console.log(imageUrl)
    window.open(imageUrl, '_blank');         
  };
  const [activeSize, setActiveSize] = useState("Square");
const handleImgSize = (e) => {
  console.log(e.target.value)
  setActiveSize(e.target.value)
};
const [autoLoader, setAutoLoader] = useState(false);
    const onAutoPrompt = async (e) => { 
    if (isAuthenticated()) {      
       const imgPrompt = {
          prompt: formValue.prompt,
          tokens: 100,
          cat: 'stock image'
       };
       if(formValue.prompt.length < 5){
        toast.error("Please enter prompt keywords")
        return;
       }
      //  console.log(formValue.prompt);
       try{
        const imagePrompt = await httpsCallable(functions, 'imageGeneratorPrompt');
        setAutoLoader(true)
        imagePrompt(imgPrompt).then((response) => { 
            let copyText = response.data.choices[0].text.replace(/\n/g, '').replace((/<br\s*[\/]?>/gi), "\n").replace(/&nbsp;/g, ' ');    
            setFormValue({...formValue, prompt: copyText})
            setAutoLoader(false)
        });
       }catch{
        setAutoLoader(false)
       }
      }
  }; 
  return (
    <> 
        <Container className={`columns ${sideNav ? "" : "justify"}`}>
      <div className="side-bar-nav column">
          <Sidebar/>
         </div>    
      <div className="main-layout column">  
          <section className="tool-panel row">
          <Col className={`ai-board outputCol col m-0 p-0 ${editMode ? "col-md-12" : "col-md-8"}`}>  
                  <section className="tool-header"> 
                    <div className="text-right">
                    <Button variant="secondary" className="dwnBtn m-0 mr-3" type="submit" 
                                  disabled={loading && `disabled`} onClick={handleDownload}>
                                    <DownloadIcon/>  <span>Download</span>
                                  </Button>  
                                  <Button variant="secondary" className="m-0 upscaleBtn" type="submit" 
                                  disabled={loading && `disabled`}>
                                    <ScaleIcon/>  Upscale
                                  </Button> 
                    </div>
                    {clockEta &&  <div className="clockEta text-left float-left">
                       <span>{clockEta} {countdown} {` seconds`}</span> <span className="imgloader"></span> 
                    </div>}
                    <div className="imgZoom text-left"> 
                      <button onClick={handleMinusClick}>-</button>
                      <p>{zoomPercentage}%</p>
                      <button onClick={handlePlusClick}>+</button>
                    </div>
                          
                            </section> 
                    <Card className="ai-board-body canvas" ref={aiBoard}> 
                           {loading &&<div className="genMoreLoad"> <Loader /> </div> }  

                           <div className="newImage" ref={elementRef} style={{ transform: `scale(${scale})` }}> 
                           { imageUrl && <img src={imageUrl} alt="Generated image" /> }  
                         </div>

                         
                      </Card>  
                  </Col>
               
                    <Col className="col-md-4 m-0 p-0 inputCol">  
                         <section className="tool-header">
      <div>
      <div className="svgIcon"> 
        <BookCoverIcon/>
        </div>
       <div>
       <h2>Book Cover</h2> 
       <p>Let's create stunning visuals</p>
       </div>
<Button className="showPromptBtn" variant="link" onClick={() => setIsShown(!isShown)}>&or;</Button>
       </div> 
          </section> 
                    <Card className={`inputSection ${isShown ? 'cardHide' : 'cardShow'}`}>   
                      {/* <Card.Header>
                      <h2>Content Rewriter</h2> 
                      </Card.Header> */}
                          <Card.Body>  
                          {/* <Card.Title><FcViewDetails /> Product description</Card.Title> */}
                              <Form onSubmit={onFormSubmit}> 
                                  <Form.Group className="mb-3" controlId="imagePrompt">
                                  <Form.Label>Type a detailed prompt</Form.Label>
<Button  className="float-right autoPrompt" disabled={(autoLoader || loading)  && `disabled`} variant="secondary-outline" onClick={onAutoPrompt} name="autoPrompt" size="sm">
                                    <AIIcon/> Auto prompt</Button> 
                                      <Form.Control
                                      as="textarea"
                                      maxLength="600"
                                      placeholder="Your prompt"
                                      style={{ height: '120px' }}
                                      name="imagePrompt" 
                                      value={formValue.prompt}
                                      required
                                      onChange={((e) => {setFormValue({...formValue, prompt: e.target.value})})}
                                      /> 
                                  </Form.Group> 
      
                                  {autoLoader && <div className="promptLoader"><Loader /></div>}
                                  <Form.Group className="mb-3" controlId="imageNegativePrompt">
                                  <Form.Switch
                                      id="custom-switch"
                                      label="Negative prompt"
                                      className="negativePrompt"
                                      checked={checked}
                                      onChange={handleSwitchChange}
                                    /> 
                                    {checked && 
                                      <Form.Control
                                      as="textarea"
                                      maxLength="600"
                                      placeholder="Items you don't want in the image"
                                      style={{ height: '100px' }}
                                      name="imageNegativePrompt"
                                      /> }
                                  </Form.Group> 
                                  <Form.Group className="mt-3 mb-3 row">
                                    <Col className="col-12">
                                        <Form.Label>Image Size</Form.Label>    
                                     <div className="imageSizeRadio">
                                    <Form.Check
                                          type="radio"
                                          label="Square"
                                          id="option1"
                                          name="imageSize"
                                          value="Square"
                                          inline
                                          bsPrefix="custom-radio custom-control" 
                                          onChange={handleImgSize}
                                          className={activeSize == "Square" ? "active" : ""}
                                        />
                                        <Form.Check
                                          type="radio"
                                          label="Horizontal"
                                          id="option2"
                                          name="imageSize"
                                          value="Horizontal"
                                          inline
                                          bsPrefix="custom-radio custom-control" 
                                          onChange={handleImgSize}
                                          className={activeSize == "Horizontal" ? "active" : ""}
                                        />
                                        <Form.Check
                                          type="radio"
                                          label="Vertical"
                                          id="option3"
                                          name="imageSize"
                                          value="Vertical"
                                          inline
                                          bsPrefix="custom-radio custom-control" 
                                          onChange={handleImgSize}
                                          className={activeSize == "Vertical" ? "active" : ""}
                                        /> 
                                    </div>
                                    </Col>   
                                    <Col className="col-12 mt-3">  
                                       <Form.Label>Select Model</Form.Label>
                                       <Button  className="selModel w-100" variant="secondary" name="imageModel" size="md" onClick={selectImageModel} value={selectedValue}>{selectedValue}</Button>
                                   </Col> 
                                  </Form.Group> 
                     {/*              <Form.Group className="mt-3 mb-3 row">   
                                    <Col>                                    
                                    <Form.Label>Model</Form.Label>
                                        <Form.Select aria-label="image Model" name="imageModel" className="form-control">  

<option value="midjourney">MidJourney V4</option>
<option value="anything-v3">Anything V3</option>
<option value="wifu-diffusion">Wifu Diffusion</option>
<option value="arcane-diffusion">Arcane Diffusion</option>
<option value="tron-legacy-diffusion">Tron Legacy Diffusion</option>
<option value="mo-di-diffusion">Mo Di Diffusion</option>
<option value="redshift-diffusion">Redshift Diffusion</option>
<option value="robo-diffusion">Robo Diffusion</option>
<option value="food-crit">Jaks Creepy Critter Pack</option>
<option value="synthwave-diffusion">SynthwavePunk</option>
<option value="midjourney-papercut">MidJourney PaperCut</option>
<option value="analog-diffusion">Analog Diffusion</option>
<option value="f222-diffusion">F222</option>
<option value="wavy-diffusion">Wavyfusion</option>
<option value="woolitize-diffusion">Woolitize</option>
<option value="vintedois-diffusion">Vintedois</option>
<option value="portraitplus-diffusion">Portrait+</option>
<option value="naturitize-diffusion">Naturitize Image Pack</option>
<option value="modelshoot-diffusion">Modelshoot Style</option>
<option value="lowpoly-diffusion">Low Poly World</option>
<option value="icons-diffusion">IconsMI</option>
<option value="linkedin-diffusion">Linkedin Photoshoot</option>
<option value="firewatch-diffusion">Firewatch Diffusion</option>
<option value="exposure-diffusion">Double Exposure</option>
<option value="disco-diffusion">Disco Diffusion</option>
<option value="protogen-3.4">Protogen x3.4</option>
<option value="midjourney-v2">OpenJourney V2</option>
<option value="redream">redream</option>
<option value="dreamshape">DreamShaper</option>
<option value="dreamm">dreamm</option>
<option value="dream-shaper-8797">Dream Shaper</option>
<option value="anything-v4">Anything V4</option>
<option value="babes">Babes</option>
<option value="stable-diffu">Stable Diffusion 2.0</option>
<option value="stable-diffu-5089">Stable Diffusion 2.1</option>
<option value="realistic-vi">Realistic Vision V1.2</option>
<option value="dreamlike-ph">Dreamlike Photoreal 2.0</option>
<option value="dreamlike-ph-4706">Dreamlike Photoreal 2.0</option>
<option value="dreamlike">Dreamlike Photoreal 2.0</option>
<option value="realistic-vi-3441">Realistic Vision V1.2</option>
<option value="deliberate">Deliberate</option>
<option value="t-shirt-prin">T-shirt print designs</option>
<option value="elldreths-vi">Elldreth's Vivid Mix</option>
<option value="hassanblend-1512">HassanBlend 1.5.1.2</option>
<option value="spybg">SPYBG</option>
<option value="realistic-vision-v13">Realistic Vision V1.3</option>
<option value="samdoesarts-ultmerge">Samdoesarts Ultmerge</option>
<option value="project-unreal-engin">Project Unreal Engine 5</option>
<option value="t-shirt-diffusion">t-shirt diffusion</option>
<option value="gta5-artwork-diffusi">GTA5 Artwork Diffusion</option>
<option value="cyberpunk-anime-diff">Cyberpunk-Anime-Diffusion</option>
<option value="anime-nai">anime-nai</option>
<option value="pastel-mix">pastel-mix</option>
<option value="waifu-diffusion">waifu-diffusion</option>
<option value="animefull">animefull</option>
<option value="hasdx">HASDX</option>
<option value="kenshi">Kenshi</option>
<option value="pastel2">pastel2</option>
<option value="pastel-v2">pastel-v2</option>
<option value="rpg-v4-model">RPG-V4-Model</option>
<option value="pastel-fix">pastel-fix</option>
<option value="animefull2">animefull2</option>
<option value="pastel-2">pastel-2</option>
<option value="inkpunk">Inkpunk</option>
<option value="pastel-blend">pastel-blend</option>
<option value="pastel-mixed">pastel-mixed</option>
<option value="coloring-book">Coloring Book</option>
<option value="coloring-page">Coloring Page</option>
<option value="moistmix">MoistMix</option>
<option value="chilloutmix">ChilloutMix</option>
<option value="dreamlike-diffusion-">dreamlike-diffusion-1.0</option>
<option value="protogen-nova">Protogen Nova</option>
<option value="qgo-10b">QGO-10b</option>
<option value="abyssorangemix2---ha">AbyssOrangeMix2 - Hardcore</option>
<option value="counterfeit-v25">Counterfeit-V2.5</option>
<option value="abyssorangemix2">AbyssOrangeMix2</option>
<option value="orangemix">orangemix</option>
<option value="foto-assisted-diffus">Foto-Assisted-Diffusion</option>
<option value="chilloutmix-ni">Chilloutmix-ni</option>
<option value="has-3dkx-11">H&amp;A's 3DKX 1.1</option>
<option value="plat-diffusion-v131">Plat Diffusion v1.3.1</option>
<option value="rev-animated">ReV Animated</option>
<option value="rgb-model">RGB model</option>
<option value="corneos-7th-heaven-m">Corneo's 7th Heaven Mix</option>
<option value="counterfeit-v20">Counterfeit-V2.0</option>
<option value="orangecocoa-5050-mix">OrangeCocoa 50/50 Mix</option>
<option value="ckptorangecocoamix">ckpt/OrangeCocoaMix</option>
<option value="ckptorangecocoamix-2">ckpt/OrangeCocoaMix 2</option>
<option value="test-1">Test 1</option>
<option value="sunshinemix">Sunshinemix</option>
<option value="protogen-infinity-of">Protogen Infinity Official Release</option>
<option value="all-in-one-pixel-mod">All-In-One-Pixel-Model</option>
<option value="pixelart">pixelart</option>
<option value="pixelart-v2">pixelart-v2</option>
<option value="anime-test-model">Anime Test Model</option>
<option value="realistic3d-model">Realistic3D Model</option>
<option value="pixel-sprite">Pixel-Sprite</option>
<option value="pixel-art-diffusion">Pixel Art Diffusion</option>
<option value="pixel-art-v3">pixel art v3</option>
<option value="midjourney-v4-painta">Midjourney-v4-PaintArt</option>
<option value="graffitymidjourney">GraffityMidjourney</option>
<option value="waifu14">Waifu1.4</option>
<option value="oscavatar">Oscavatar</option>
<option value="pastel-new3">pastel-new3</option>
<option value="pastel-new4">pastel-new4</option>
<option value="unstableinkdream">UnstableInkDream</option>
<option value="pastel-new5">pastel-new5</option>
<option value="makima-lora">Makima Lora</option>
<option value="yor-forger-lora">Yor Forger Lora</option>
<option value="aqua-lora">Aqua Lora</option>
<option value="realdosmix">RealDosMix</option>
<option value="eula-lawrence-model">Eula Lawrence Model</option>
<option value="setsuna-yuki-test-mo">Setsuna Yuki Test Model</option>
<option value="perfume-test-model">Perfume Test Model</option>
<option value="liberty">Liberty</option>
<option value="liberty-20">Liberty 2.0</option>
<option value="chilloutmixsf">ChilloutMixSF</option>
<option value="abyssorangemix2nsfw">AbyssOrangeMix2NSFW</option>
<option value="abyssorangemixsfw">AbyssOrangeMixSFW</option>
<option value="grapefruit-nsfw-anim">Grapefruit NSFW anime</option>
<option value="wojak-diffusion">Wojak-Diffusion</option>
<option value="wojaks-now">Wojaks now</option>
<option value="dalcefopainting">dalcefo_painting</option>
<option value="dalcefopainting2">dalcefo_painting2</option>
<option value="yor-briar-lora">Yor Briar Lora</option>
<option value="anime-test-model-v2">Anime Test Model v2</option>
<option value="qgo-10b-ckpt">QGO-10b-ckpt</option>
<option value="qgo-10bsft">QGO-10bsft</option>
<option value="yae-miko-genshin">Yae Miko Genshin</option>
<option value="yae-miko-lora">Yae Miko Lora</option>
<option value="sd-15">SD 1.5</option>
<option value="xinggeai">xinggeai</option>
<option value="xinggeailora">xinggeai_lora</option>
<option value="xinggeailoratest">xinggeai_loratest</option>
<option value="vector-art">vector-art</option>
<option value="hasbullah">Hasbullah</option>
<option value="hasbullah">Hasbullah</option>
<option value="hasbullah">Hasbullah</option>
<option value="hasbullah">Hasbullah</option>  
 
                                        </Form.Select>
                                    </Col>
                                  </Form.Group>  */}
                                  
                                  <Button variant="primary" className="mt-3 mb-3 w-100" type="submit" 
                                  disabled={loading && `disabled`}>
                                      Generate
                                  </Button> 

                                  <div className="advanceOptions">
                                  <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Advanced Settings</Accordion.Header>
        <Accordion.Body>
        <Form.Group className="mt-3 mb-3 row">
                                    <Col className="col-12 mb-3">
                                        <Form.Label>Guidance Scale <span className="rangeValue">{rangeValue}</span></Form.Label>    
                                        <Form.Range min={1} max={20} value={rangeValue} onChange={handleRangeChange} />
                                        <Form.Text muted>Any number between 1 and 20. The guidance scale adjusts how much the image looks closer to the prompt(description). </Form.Text>
                                    </Col> 
                                    <Col className="col-12 mb-3">
                                        <Form.Label>Steps <span className="rangeSteps">{rangeSteps}</span></Form.Label>     
                                        <Form.Range min={1} max={50} value={rangeSteps} onChange={handleRangeStepsChange} />
                                        <Form.Text muted>  Number of denoising steps (minimum: 1; maximum: 50)</Form.Text>
                                    </Col> 
                                    <Col className="col-12 mb-3">   
                                        <Form.Label htmlFor="imageSeed">Seed</Form.Label>
                                        <Form.Control
                                          type="number"
                                          id="imageSeed"
                                          name="imageSeed" 
                                          aria-describedby="imageSeed"
                                        />
                                        <Form.Text muted>
                                        Leave it blank to use random value. By setting seed value (any number) you can generate reproducible image. Seed can be any random number.
                                        </Form.Text>
                                    </Col>
                                    {/* <Col className="col-12 mb-3">   
                                        <Form.Label htmlFor="imageSteps">Steps</Form.Label>
                                        <Form.Control
                                          type="number"
                                          id="imageSteps"
                                          name="imageSteps" 
                                          aria-describedby="imageSteps"
                                        />
                                        <Form.Text muted>
                                        Number of denoising steps (minimum: 1; maximum: 50)
                                        </Form.Text>
                                    </Col> */}

                                    <Col className="col-12 mb-3">
                                        <Form.Label>Sampler</Form.Label>    
                                        <Form.Select name="imageSampler" id="imageSampler" className="form-control">  
                                          <option value="none">None</option>
                                          <option value="ddim">ddim</option>
                                          <option value="plms">plms</option>
                                          <option value="k_euler">k_euler</option>
                                          <option value="k_euler_ancestral">k_euler_ancestral</option>
                                          <option value="k_heun">k_heun</option>
                                          <option value="k_dpm_2">k_dpm_2</option>
                                          <option value="k_dpm_2_ancestral">k_dpm_2_ancestral</option>
                                          <option value="k_dpmpp_2s_ancestral">k_dpmpp_2s_ancestral</option>
                                          <option value="k_lms">k_lms</option>
                                          <option value="k_dpmpp_2n">k_dpmpp_2n</option>
                                      </Form.Select>
                                    </Col> 
                                  </Form.Group> 
                            
          </Accordion.Body>
          </Accordion.Item>
          </Accordion>
          </div> 

                                  </Form>
                                  {/* <pre>{ JSON.stringify(formValue.name.length, undefined, 2)}</pre>       */}
                            </Card.Body>  
                      </Card>
                    </Col>
                   
              
              
          </section>
        </div>
      </Container>
      <Toaster position="top-center" reverseOrder={false} />


       <Modal show={showModal} onHide={handleClose} keyboard={false} className={selectModel ? "imageSelectionModal" : "" || loginState ? "loginState" : "" || upgradeModel ? "upgradeModal": ""}>
          <div>{selectModel}</div>
          {loginState && !upgradeModel &&  !selectModel && <Modal.Body className="text-center">  
             <InlineLogin onLoginSuccess={handleLoginSuccess}/>
          </Modal.Body>}
         {upgradeModel &&
           <Modal.Body className="text-center">
            <div className="upgradeIcon"><UpgradeIcon/></div>
            <h4>Upgrade your account</h4>
            <p>You have either exceeded the credit limit or your plan has expired. <br/>Please upgrade your account.</p>
            <Button className="upgradeBtn" variant="primary" size="md" onClick={()=> navigate(`/pricing`)}>Upgrade</Button>
          </Modal.Body>
         }
         {selectModel &&
         <Modal.Body className="text-center">
         <h4>Choose a model</h4>
              <div className="modelSelection"><Form>
                <Form.Check type="radio" label="Analog Diffusion" name="Analog Diffusion" id="analog-diffusion" value="analog-diffusion" checked={selectedValue === 'analog-diffusion'} onChange={handleRadioChange} />
                <Form.Check type="radio" label="Anime Diffusion" name="Anime Diffusion" id="cyberpunk-anime-diff" value="cyberpunk-anime-diff" checked={selectedValue === 'cyberpunk-anime-diff'} onChange={handleRadioChange} />
                <Form.Check type="radio" label="Disco Diffusion" name="Disco Diffusion" id="disco-diffusion" value="disco-diffusion" checked={selectedValue === 'disco-diffusion'} onChange={handleRadioChange} />
                <Form.Check type="radio" label="Real Photo" name="Real Photo" id="dreamlike" value="dreamlike" checked={selectedValue === 'dreamlike'} onChange={handleRadioChange} />
                <Form.Check type="radio" label="Portrait Plus" name="Portrait+" id="Portrait Plus" value="portraitplus-diffusion" checked={selectedValue === 'portraitplus-diffusion'} onChange={handleRadioChange} />
                <Form.Check type="radio" label="(3d) Redshift Diffusion" name="(3d) Redshift Diffusion" id="redshift-diffusion" value="redshift-diffusion" checked={selectedValue === 'redshift-diffusion'} onChange={handleRadioChange} />
                <Form.Check type="radio" label="Stable Diffusion" name="Stable Diffusion" id="stable-diffusion" value="stable-diffusion" checked={selectedValue === 'stable-diffusion'} onChange={handleRadioChange} />
                <Form.Check type="radio" label="Mo-Di" name="Mo-Di" id="mo-di-diffusion" value="mo-di-diffusion" checked={selectedValue === 'mo-di-diffusion'} onChange={handleRadioChange} />
              </Form>
            </div>
            </Modal.Body>}
         </Modal> 

    </>
  );
};

export default ImageCreator;



