import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
} from "firebase/auth";
import {useNavigate, Link} from 'react-router-dom'
import { auth } from "../firebase";

const userAuthContext = createContext();
 
export function UserAuthContextProvider({ children }) {
 // const [user, setUser] = useState({});
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true);

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password)
    .then(() => {
      sendEmailVerification(auth.currentUser)   
      .then(() => {  
        navigate('/verify-email')
      }).catch((err) => alert(err.message))
    })
    .catch(err => alert(err.message));
  }
  function passwordReset(email) {
    //console.log(email)
    return sendPasswordResetEmail(auth, email);
  } 
  function logOut() {
    localStorage.removeItem("user");
    return signOut(auth);
  }
  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
  }
  function justData(val) {
    const simpleData = val + 20
    return simpleData;
  }
  const [user, setUser] = useState();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
      //console.log("Auth", user);
      //localStorage.setItem("user", user.uid)
    }); 
    return unsubscribe; 
  }, []);

  return (
    <userAuthContext.Provider value={{ user, logIn, signUp, logOut, googleSignIn, passwordReset, justData}}>
      {!loading && children}
    </userAuthContext.Provider>
  );
} 
export function useUserAuth() {
  return useContext(userAuthContext);
} 