
import {React, useState, useEffect, useContext, useRef} from "react"; 
import { Button, Card, Form, Container, Row, Col, Accordion } from "react-bootstrap";
import "./History.css";  
import { useUserAuth } from "../../../context/UserAuthContext";
import CommonDataServices from "../../../services/common.services";
import { DownloadIcon } from "../../../assets/svg/SvgIcons";
import Loader from "../../shared/utilities/loader/Loader";

const History = () => { 
  const { user } = useUserAuth();
  const [historyData, setHistoryData] = useState();
  const [noResults, setNoResults] = useState(false);
  const [lastDoc, setLastDoc] = useState(null);
  const [loadMore, setLoadMore] = useState(false);
 
  useEffect(() => {
    getHistory();  
    return () => {
      // Unsubscribe or cleanup logic here
    };
   },[]) 

  const getHistory = async () =>{
    //console.log('call') 
    console.log(lastDoc)
    try{ 
      await CommonDataServices.getHistory(user.uid, lastDoc).then((data) => { 
       // console.log(data) 
             setLoadMore(false)
             if(data){ 
              //console.log(data.lastDoc)
              setLastDoc(data.lastDoc);
             }
              if(data.docs.length > 0){ 
                data.docs.map(doc => {  
                //  console.log(doc.data());
                  setHistoryData(prevState => {
                    if (Array.isArray(prevState)) {
                      return [...prevState, doc.data()];
                    } else { 
                      return [doc.data()];
                    }
                  }); 
                }); 
            } else { 
              console.log("no results")
                   setNoResults(true);
                   setLoadMore(false)
                  // setLoading(false);
                  // setNoRecords(true)
            }
        });   
      } catch (err) { 
      console.log(err.message); 
    } 
  }
  const handleLoadMore = () => {
  //  console.log(lastDoc);
    setLoadMore(true)
    getHistory();
  }
  const handleDownload = (e) => { 
    window.open(e.target.name, '_blank');         
  };
    return ( 
     <>
     <div className="header-txt text-center"><h2>Imaginative Creations</h2></div>
        {!historyData && !noResults && <Loader/>}
        <div className="images-wrapper"> 
          {noResults && <div className="mx-auto my-5 text-center w-100 no-results">No images found!</div>}
          {historyData && historyData.map((dataItem, index) => (
            <figure className="image-item" key={index}>
              {/* <div className="image-meta">
                  <p>{dataItem.meta.prompt}</p>
                  <p>{dataItem.id}</p>
              </div>  */} 
            
                <img src={dataItem.output[0]} className="generated-image"/>
                <figcaption><Button variant="link" className="img-download" type="submit" 
                                  onClick={handleDownload} name={dataItem.output[0]}>
                                   <DownloadIcon />  
                                    Download                                    
                                  </Button> </figcaption>
           
              
            </figure>
          ))}
        </div>
        {loadMore && <Loader/>}
        {lastDoc &&
          <div className="loadMoreCol col text-center my-5">
          <Button variant="secondary" onClick={handleLoadMore}>Load More..</Button>
       </div>}
     </>
    );  
};

export default History;