// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"; 
import { getAuth } from "firebase/auth";
import { getFirestore } from "@firebase/firestore";
import { getFunctions } from 'firebase/functions';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC5yWoAqDQoam6Ydzp7yAEkluhczq2sBOs",
  authDomain: "imagekraft-5e0eb.firebaseapp.com",
  projectId: "imagekraft-5e0eb",
  storageBucket: "imagekraft-5e0eb.appspot.com",
  messagingSenderId: "974724453237",
  appId: "1:974724453237:web:bbcaad33eb38a02b74f93c",
  measurementId: "G-GB2TYDSPDZ" 
};
 
// Initialize Firebase
const app = initializeApp(firebaseConfig); 
export const functions = getFunctions(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export default app; 