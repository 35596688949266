import {React, useState, useEffect, useContext} from "react";
import { Button, Card, Container} from "react-bootstrap"; 
import { Link } from "react-router-dom";
import { useUserAuth } from "../../../context/UserAuthContext";  
import toast, { Toaster } from 'react-hot-toast'; 
import { FcPaid } from "react-icons/fc";
import TemplatesData from "../../../services/templaetsData/TemplatesData";
import "./Templates.css"; 
import { YouTubeIcon, TwitterIcon, QuoraIcon, FacebookIcon, LinkedinIcon, AmazonIcon, GoogleAdIcon, CustomerReviewIcon, InstagramIcon, ProductReviewIcon, BlogIcon, BlogIdeaIcon, BlogTitleIcon, BlogIntroIcon, BlogOutlineIcon, BlogConclusionIcon, ParagraphIcon, ContentRewriteIcon, WebsiteHeadingIcon, WebsiteSubHeadingIcon, MetaTitleIcon, MetaDescriptionIcon, AboutUsIcon, FaqsIcon, CallToActionIcon, AidaIcon, EmailIcon, ParaphraseIcon, UniversalIcon, TempIcon } from "../../../assets/svg/SvgIcons";

const Templates = () => {
  const { user } = useUserAuth();
  //if(user){ localStorage.setItem("user", user.uid)} 
 
  const [tempObj, setTempObj] = useState(null);
  const [selctedItem, setSelctedItem] = useState('all')
  const [allItem, setAllItem] = useState(true) 
  const [tempHolder, setTempHolder] = useState(null)
 
 
  useEffect(() => {
    if(TemplatesData){
      setTempObj(TemplatesData); 
    }  
    if(tempHolder){
      setTempObj(tempHolder); 
    }  
    //console.log(tempObj)
  });
   
 const templateGroup =(e)=> {
   const categoryType = e.target.title;
   const tempData = TemplatesData; 
   setSelctedItem(categoryType); 
   if(categoryType !== "all"){
      const filterObject = (obj, filter, filterValue) =>  
      Object.keys(obj).reduce((acc, val) => 
      (obj[val][filter] !== filterValue ? acc : {
                ...acc,
                [val]: obj[val]
            }                                        
      ), {});
      setTempHolder(filterObject(tempData, "category", categoryType));
   } else{
    setTempHolder(tempData);  
   }
  }

  return (
    <> 
      {/* <div className="p-4 box mt-3 text-center">
        Hello Welcome to Templates <br />
        {user && user.displayName}
      </div> */}
        <Container>
      <div className="main-layout templates">               
                <div className="header-txt"> 
                    <h2>Templates</h2> 
                    <p className="text-center"><strong>Huge collection of ready to use templates.</strong><br/> 
                    Our AI templates are designed to help you generate high quality content quickly and easily.</p>  
                    </div>  
            <div className=""> 

            <div className="card-sort-btns mb-4">
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'all' ? "active" : "")} title="all" onClick={templateGroup}>All<span className="badge badge-light" title="all">78</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'business' ? "active" : "")} title="business" onClick={templateGroup}>Business<span className="badge badge-light" title="business">20</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'marketing' ? "active" : "")} title="marketing" onClick={templateGroup}>Marketing<span className="badge badge-light" title="marketing">4</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'career' ? "active" : "")} title="career" onClick={templateGroup}>Careers<span className="badge badge-light" title="career">7</span></Button> 
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'hr' ? "active" : "")} title="hr" onClick={templateGroup}>HR<span className="badge badge-light" title="hr">11</span></Button> 
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'email' ? "active" : "")} title="email" onClick={templateGroup}>Emails<span className="badge badge-light" title="email">27</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'personal' ? "active" : "")} title="personal" onClick={templateGroup}>Personal<span className="badge badge-light" title="personal">14</span></Button>
               </div> 
          <div className="card-grid-row mt-1"> 
        
   
          {tempObj && Object.keys(tempObj).map((obj, index) =>  
                 <Card title="template" key={index} className={tempObj[obj].category}> 
                 <Link to={`/flexy-template/?type=${tempObj[obj].title}`}>
                   <div className="card-wrap">
                         <div className="card-icon">
                           <div className="svgIcon">
                         {/* <BlogIcon /> */}
                         <TempIcon/>
                         </div>
                         </div>
                          <Card.Body> 
                        <Card.Title>{tempObj[obj].title}</Card.Title>
                        <Card.Text> 
                         {tempObj[obj].caption}
                        </Card.Text> 
                       </Card.Body> 
                      </div>
                     </Link>
                </Card> 
          )}


 
             </div>
          </div>
        </div>
      </Container>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default Templates;
 