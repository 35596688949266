const HRData = {  

interviewFollowUpLetter : {
title:"Interview follow-up letter",  
caption:"Help's you to write an interview follow-up letter that will impress.",
category:"hr", 
inputs : 
`Information:
To: [name of a person]
Interview overview:
Expression of thanks:
Your skills and qualifications:
close note`
},
offerLetter : {
title:"Offer letter",  
caption:"This AI template can help you write an offer letter that will get results.",
category:"hr", 
inputs : 
`Job title: [write here..]
Job description: [write here..]
Salary: [write here..]
Benefits: [write here..]
Start date: [write here..]
The company name and contact information`
},
appointmentLetter : {
title:"Appointment letter",  
caption:"This AI template can help you write an appointment letter that will get results.",
category:"hr", 
inputs : 
`Hired for: [write here..] 
Starting date: [write here..]
Salary: [write here..]
Benefits: [write here..] 
Expectations: [write here..]`
},
appraisalletter : {
title:"Appraisal letter",  
caption:"This AI template can help you write an appraisal letter that will get results.",
category:"hr", 
inputs : 
`Job title: [write here..]
Performance: [Employee's  performance]
Contirubution to the companty: [write here..]
Improvement: [write here..]

Format 
Express employee's contribution to the company
Express Areas of improvement
Express employee's future potential
close note`
},
promotionLetter : {
title:"Promotion letter",  
caption:"This AI template can help you write a promotion letter that will get results.",
category:"hr", 
inputs : 
`Information:
To: [name of a person]
Promotion reason: [write reason]
Effective date: [date]
Benefits: [write new benifits]
Salary: [write revised salary]`
},
experienceLetter : {
title:"Experience letter",  
caption:"This AI template can help you write an experience letter that will get results.",
category:"hr", 
inputs : 
`Job role: [name of a person]
Employe name: [name]
Company: [company name]
Date of joining: [current job title]
Skills and Experience gained: [Write the skills and experience]
Date of Resignation: [date of resignation]
Date of issuing the letter: [write date]
Express employe work ethic and character
Express employe future potential`
},
bonafideLetter : {
title:"Bonafide letter",  
caption:"This AI template can help you write a bonafide letter that will get results.",
category:"hr", 
inputs : 
`purpose of the letter:[write here..]
Letter for: [write here..]
Information to include:[write here..]
Date: [write here..]`
}, 
payslipLetter : {
title:"Payslip letter",  
caption:"This AI template can help you write a payslip letter that will get results.",
category:"hr", 
inputs : 
`Company name: [write here..]
Pay period: [write here..]
Address of the employee: [write here..]
Address of the employer: [write here..]
Amount of pay earned: [write here..]
Amount of deductions made: [write here..]
Net pay:[write here..]`
},
relievingLetter : {
title:"Relieving letter",  
caption:"This AI template can help you write a relieving letter for your employees.",
category:"hr", 
inputs : 
`Date of letter: [write here..]
Contact information of the employee: [write here..]
Contact information of the employer: [write here..]
Reason for the employee's departure: [write here..]
Last day of work: [write here..]
Express statement of appreciation for the employee's contributions
Express best wishes for the employee's future`
},
employmentCertificate : {
title:"Employment certificate",  
caption:"This AI template can help you create an employment certificate for your employees.",
category:"hr", 
inputs : 
`Dates of employment: [write here..]
State the position held and duties performed: [write here..] 
Addressed to the individual requesting the certificate: [write here..]`
}, 
jobDescription : {
title:"Job description",  
caption:"This AI template can help you write an attractive and engaging job description.",
category:"hr", 
inputs : 
`Job title: [write here..]
A brief description of the company: [write here..]
Location: [write here..]
Responsibilities: [write here..]
Qualifications required: [write here..]
Pay range: [write here..]`
}
}
export default HRData;