import React from "react";  
 
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "react-bootstrap"; 

export default function Landing() {
  return (
    <div> 
      <Container>
        <Row>
          <Col><h1>Hello world</h1></Col>
        </Row>
      </Container> 
    </div>
  )
}
