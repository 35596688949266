const MarketingData = { 
digitalMarketingPlan : {
title:"Digital marketing plan",
caption:"This AI template will help you to create a successful marketing strategy.",
category:"marketing",
inputs :`Company: [company name]
Business goals: [business goals/objectives]
Audience: [target audience]
Budget: [spend on marketing]
Timeline: [deadlines]
Channels: [Social Media/SEO/PPC/Email Marketing/SMM/Affiliate]

Format:
Introduce the company
Describe the business goals
List the marketing channels to reach target audience
Frame the timeline to reach the business goals`
},
businessPlan : {
title:"Business plan",
category:"marketing",
caption:"Business plan AI template can help you to start and grow your business.",
inputs :`Executive summary: [business, goals, strategies and product and services]
Company description: [about your business, history, ownership and team]
Products and services: [products or services you offer]
Market analysis: [target market]
Sales and marketing: [marketing strategies]
Financial projections: [financial plan, income and expenses]
Implementation plan: [milestones and a timeline]
Exit strategy: [selling, public or passing to family members] 

Format:
Introduce the business
Explain the benefits of the business
Explain how the business solves the problem
Explain the market for this product or services
Explain how reach the target audience and make business`
},
realEstateListing : {
title:"Real estate listing",
caption:"Help's you to write an effective real estate listing that will sell your property",
category:"marketing",
inputs :`Property name: [name of the property]
Property type: [type of the property]
Location: [location of the property]
Size: [size of the property]
Price: [price of the property]
Amenities: [amenities of the property]
Description: [description of the property, why to buy, advantages]

Format:
Introduce the property
Explain the property details like location, size, price, and amenities
Explain the benefits of buying now
Explain how to get in touch`
},
pressRelease : {
title:"Press release",
caption:"This AI template can help you write a press release that will get results.",
category:"marketing",
inputs :`Date: [date] 
Name of the organization: [name of the organization]
Products and services: [write about products or services]
Announcement: [write your announcements]
Stakeholder name: [name]
Contact details: [write contact details]

Format:
Start with "FOR IMMEDIATE RELEASE"
Write Headline
Write Subhead (optional)
Instructions: Write press release in detail around 500 word count`
}
}
export default MarketingData;