import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Button, Nav, Accordion, Container, NavLink, Tabs, Tab } from "react-bootstrap";
import { useNavigate } from "react-router";
import toast, { Toaster } from 'react-hot-toast'; 
import { Link } from "react-router-dom";
import TabContainer from 'react-bootstrap/TabContainer'
import { useUserAuth } from "../../context/UserAuthContext";   
import { getFunctions, httpsCallable } from "firebase/functions";  
import Loader from "../shared/utilities/loader/Loader";
import Modal from 'react-bootstrap/Modal';  
import axios from "axios";
    import "./Pricing.css"; 
import InlineLogin from "../InlineLogin";


let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe("pk_live_51MnPxuSBZDJgT6mT8Av7kOvz1l8gdWwCTfINpgCjIwulioARDzyMrIfSezoSGxTv6Tt2Z9qLUxymfOpu3knkkMXa00t9yN5o5D");
  }

  return stripePromise;
};

const Pricing = () => {  
  const [showModal, setShowModal] = useState(false);
  const [loginState, setloginState] = useState(false); 
  const [loading, setLoading] = useState(false);
  const { user } = useUserAuth();
  const handleClose = () => setShowModal(false);
  const [planObjMonth, setPlanObjMonth] = useState([
    {planType: 1, planPrice: "590", planWords: "200", planPriceID:"price_1M1UGrSJsVlrtsJga8yIsBNe"},
    {planType: 2, planPrice: "1,190", planWords: "600", planPriceID:"price_1M1UKVSJsVlrtsJgQhvArPD2"},
    {planType: 3, planPrice: "2,290", planWords: "1000", planPriceID:"price_1M1ULOSJsVlrtsJg4KBtD5KW"},
    {planType: 6, planPrice: "8,900", planWords: "3500", planPriceID:"price_1M1UMwSJsVlrtsJgY6fQkdIG"}
  ]) 
  const [planObjYear, setPlanObjYear] = useState([
    {planType: 1, planPrice: "390", planWords: "200", planPriceID:"price_1M1UV4SJsVlrtsJga0iztLqi", planAnnualPrice: "4680"},
    {planType: 2, planPrice: "790", planWords: "600", planPriceID:"price_1M1UW6SJsVlrtsJgnj7p5kA8", planAnnualPrice: "15120"},
    {planType: 3, planPrice: "1,490", planWords: "1000", planPriceID:"price_1M1UWOSJsVlrtsJgbJIXghhs", planAnnualPrice: "19920"},
    {planType: 6, planPrice: "7,900", planWords: "3500", planPriceID:"price_1M1UXiSJsVlrtsJgN8GPPl2I", planAnnualPrice: "119880"}
  ])
  const [planObjMonthUs, setPlanObjMonthUs] = useState([
    {planType: "pmu1", planPrice: "9", planWords: "200", planPriceID:"price_1M1UgPSJsVlrtsJgy7RDzxWU"},
    {planType: "pmu2", planPrice: "19", planWords: "600", planPriceID:"price_1M1UgPSJsVlrtsJgEPBjLXpy"},
    {planType: "pmu3", planPrice: "49", planWords: "1500", planPriceID:"price_1M1UgPSJsVlrtsJgBz3mg6pk"},
    {planType: "pmu6", planPrice: "99", planWords: "3500", planPriceID:"price_1M1UgQSJsVlrtsJgwu0xpz39"}
  ]) 
  const [planObjYearUs, setPlanObjYearUs] = useState([
    {planType: "pyu1", planPrice: "7", planWords: "200", planPriceID:"price_1M1UkxSJsVlrtsJgPH0mQRJ6", planAnnualPrice: "60"},
    {planType: "pyu2", planPrice: "16", planWords: "600", planPriceID:"price_1M1UkxSJsVlrtsJgFBBImmgT", planAnnualPrice: "144"},
    {planType: "pyu3", planPrice: "27", planWords: "1000", planPriceID:"price_1M1UkxSJsVlrtsJgDn8wXltZ", planAnnualPrice: "228"}, 
    {planType: "pyu6", planPrice: "89", planWords: "3500", planPriceID:"price_1M1UkySJsVlrtsJg8T1AU8PC", planAnnualPrice: "1428"}
  ])
  const [selectedPlan, setSelectedPlan] = useState(1)
  const navigate = useNavigate(); 
  const functions = getFunctions(); 
  const [key, setKey] = useState('monthly');
  const [state, setState] = useState({
    ip: "",
    countryName: "",
    countryCode: "",
    city: "",
    timezone: ""
  });
  const isAuthenticated = () => {
    if(user){
      return true;
    } else {
      setloginState(true)
      setShowModal(true) 
      return false;
    } 
  } 
  //const stripe =    window.Stripe('pk_test_51Kzc9vSJsVlrtsJgysz6iWCYrNBieshzuXYk4OAdYr83Ip7u3iAfWLGYmiCWG5xY9mm703ARAjXIRtNo1UQsUlAz00vQq96pB1');
  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/") 
      .then((response) => {
        let data = response.data;
        setState({
          ...state, 
          countryName: data.country_name,
          countryCode: data.country_code, 
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  };
  const priceRange = (e) => {
    const range = e.target.value - 1;
    // //console.log(range)
    // //console.log(planObj[range].planPrice)
    let selPlan = parseInt(range);
    setSelectedPlan(selPlan);
  }
  const checkOut = async (e) => { 
    if (isAuthenticated()) {
    const priceId = e.target.id;
    const stripe = await getStripe();
    setLoading(true);
   // //console.log("clicked" + priceId) 
    const getCheckoutSession = httpsCallable(functions, 'createStripeCheckout'); 
    const param = {
      uid: user.uid,
      priceId: priceId
    }
    getCheckoutSession(param).then(async (response) => {  
          const sessionId = response.data.id;
          await stripe.redirectToCheckout({sessionId: sessionId})
          setLoading(false);
    }); 
  }
  }
  const handleLoginSuccess = (user) => { 
    setloginState(false)
    setShowModal(false)  
    console.log('Login successful');
  };
  const cancelSub = async () => { 
    const stripe = await getStripe();
    
    const delSub = httpsCallable(functions, 'deleteStripeSubscription');
    const param = {
      subId : 'subId'
    }
    delSub(param).then((response) => {
      //console.log(response)
    })  
  }
  useEffect(() => {
    getGeoInfo();
  }, []);
  return (
    <>   
      <div className="main-layout page-layout"> 
            <div className="header-txt sub-head text-center"> 
                <h2>Pricing</h2>  
                <p>Revolutionize Your Design Process: Affordable Pricing to Save You Time and Money.</p> 
                {/* <p>Country Name: {state.countryName}</p> */}
                {/* <Button variant="primary" size="md" className="w-100 buttonWrap" id="price_1L5uxqSJsVlrtsJg2PTQyZDr" onClick={checkOut}>Upgrade Now - Day 99</Button> */}
            </div>  
            <div className="container">  

               <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3 pricingTabs"
    > 
      <Tab eventKey="monthly" title="Monthly">
      <div className="loaderInline">{loading && <Loader/>}</div> 
      <div className="row">
      <div className="col-md-4"> 
<div className="card pricing-box d-flex mx-auto activebox">
    <div className="card-body">
        <h3 className="card-title">Stater</h3>
        <h5 className="card-subtitle mb-1">
        {state.countryName == "India" && <>&#8377;1599</>}
        {state.countryName !== "India" && <>&#36;19</>}
           <span className="ml-3">Per month</span></h5> 
           {/* <small className="text-muted">Cancel anytime. Billed monthly.</small> */}
        <div>
        {/* {state.countryName == "India" && <Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id="price_1L65YgSJsVlrtsJgA2ls859w" onClick={checkOut}>Upgrade Now</Button>}
        {state.countryName !== "India" &&<Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id="price_1L4mmsSJsVlrtsJg2EA66GrC" onClick={checkOut}>Upgrade Now</Button>} */}
        {/* Live */}
        <div className="planWords mt-2">
            <strong>750</strong> credits 
          </div> 
          
          {state.countryName == "India" && <Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id={planObjMonth[selectedPlan].planPriceID} onClick={checkOut}>Upgrade Now</Button>}
          {state.countryName !== "India" &&<Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id={planObjMonthUs[selectedPlan].planPriceID} onClick={checkOut}>Upgrade Now</Button>}
        </div>
        <div className="card-text my-3">
                <ul> 
                    <li><i className="tickMark">✔</i> <strong>750 Credits</strong></li>
                    <li><i className="tickMark">✔</i>Access to all tools</li>
                    <li><i className="tickMark">✔</i>1 credit is 1 image</li> 
                    <li><i className="tickMark">✔</i>Image History</li>
                    <li><i className="tickMark">✔</i>AI Upscaling to 4x</li>
                    <li><i className="tickMark">✔</i> 24/7 email support</li>
                    <li><i className="tickMark">✔</i>GPU enabled/fast generation</li> 
                </ul>
            </div>
    </div>
</div>

</div> 
      <div className="col-md-4"> 
<div className="card pricing-box d-flex mx-auto activebox">
    <div className="card-body">
        <h3 className="card-title">Professional</h3>
        <h5 className="card-subtitle mb-1">
        {state.countryName == "India" && <>&#8377;2499</>}
        {state.countryName !== "India" && <>&#36;29</>}
           <span className="ml-3">Per month</span></h5> 
           {/* <small className="text-muted">Cancel anytime. Billed monthly.</small> */}
        <div>
        {/* {state.countryName == "India" && <Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id="price_1L65YgSJsVlrtsJgA2ls859w" onClick={checkOut}>Upgrade Now</Button>}
        {state.countryName !== "India" &&<Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id="price_1L4mmsSJsVlrtsJg2EA66GrC" onClick={checkOut}>Upgrade Now</Button>} */}
        {/* Live */}
        <div className="planWords mt-2">
            <strong>1500</strong> credits
          </div> 
          
          {state.countryName == "India" && <Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id={planObjMonth[selectedPlan].planPriceID} onClick={checkOut}>Upgrade Now</Button>}
          {state.countryName !== "India" &&<Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id={planObjMonthUs[selectedPlan].planPriceID} onClick={checkOut}>Upgrade Now</Button>}
        </div>
        <div className="card-text my-3">
                <ul> 
                    <li><i className="tickMark">✔</i> <strong>1500 Credits</strong></li>
                    <li><i className="tickMark">✔</i>Access to all tools</li>
                    <li><i className="tickMark">✔</i>1 credit is 1 image</li> 
                    <li><i className="tickMark">✔</i>Image History</li>
                    <li><i className="tickMark">✔</i>AI Upscaling to 4x</li>
                    <li><i className="tickMark">✔</i> 24/7 email support</li>
                    <li><i className="tickMark">✔</i>GPU enabled/fast generation</li> 
                </ul>
            </div>
    </div>
</div>

</div> 
            <div className="col-md-4">

                <div className="card pricing-box d-flex mx-auto enterprise">
                <div className="card-body">
                        <h3 className="card-title">Enterprise</h3>
                        <h5 className="card-subtitle-custom mb-1">
                        Customized </h5> 
                        <div> 
                        <div className="planWords mt-2 mb-2">
                          Monthly package</div>
                        <div className="planWords mt-2 mb-3"><strong><i>Looking for unlimited credits?</i></strong></div>
                        <Button variant="primary-outline" disabled={loading} size="md" className="w-100 buttonWrap" target="_blank" href="mailto:info.imagekraft@gmail.com">Get in touch</Button>
                         </div>
                        <div className="card-text my-3">
                                <ul> 
                                    <li><i className="tickMark">✔</i> Includes professional plan</li> 
                                    <li><i className="tickMark">✔</i><strong className="mr-1">Unlimited</strong> credits</li>
                                    {/* <li><i className="tickMark">✔</i>Access to all tools</li> */}
                                    <li><i className="tickMark">✔</i>Customised tool</li>  
                                    <li><i className="tickMark">✔</i>Priority 24/7 support</li>
                                    <li><i className="tickMark">✔</i>GPU enabled/ultra fast generation</li> 
                                </ul>
                            </div> 
                    </div>
              
                </div>

            </div>
            
            {/* <div className="col-sm-4">
                <div className="card pricing-box d-flex mx-auto">
                    <div className="card-body">
                        <h3 className="card-title">Expert</h3>  
                        <h5 className="card-subtitle mb-1">
                        {state.countryName == "India" && <>&#8377;9900 </>}
                        {state.countryName !== "India" && <>&#36;149</>}
                           <span>Per month</span></h5> <small className="text-muted">Cancel anytime. Billed monthly.</small>
                        <div>
                        {state.countryName == "India" && <Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id="price_1L65aJSJsVlrtsJgyvD8KrJ7" onClick={checkOut}>Upgrade Now</Button>}
                        {state.countryName !== "India" &&<Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id="price_1L4mnhSJsVlrtsJgeQiVaE9j" onClick={checkOut}>Upgrade Now</Button>}
                          </div>
                        <div className="card-text text-muted my-3">
                                <ul> 
                                <li><i className="tickMark">✔</i> Unlimited words per month</li>
                                    <li><i className="tickMark">✔</i> Access to all 44 tools</li>
                                    <li><i className="tickMark">✔</i> Quick Long-Form Article Writing Tool</li> 
                                    <li><i className="tickMark">✔</i> Multilingual support</li>
                                    <li><i className="tickMark">✔</i> 24/7 email support</li>
                                </ul>
                            </div>
                    </div>
                </div>
            </div> */}
           </div>
      </Tab>
      <Tab eventKey="yearly" title="Yearly">
      <div className="loaderInline">{loading && <Loader/>}</div> 
      <div className="row">
      <div className="col-md-4">
                <div className="card pricing-box d-flex mx-auto activebox">
                    <div className="card-body">
                    <div className="offerTxt">40 % off</div>
                        <h3 className="card-title">Stater</h3>
                        <h5 className="card-subtitle mb-1">
                        {state.countryName == "India" && <>&#8377;899
                        <span className="ml-3"> Per month. Billed &#8377;10,788 yearly.</span>
                        </>}
                        {state.countryName !== "India" && <>&#36;10
                        <span className="ml-3"> Per month. Billed &#36;120 yearly.</span>
                        </>}
                            </h5> 
                           {/* <small className="text-muted">Cancel anytime. Billed yearly.</small> */} 
                           <div className="planWords mt-2">
                            <strong>750</strong> credits
                          </div> 
                        <div>
                        {/* {state.countryName == "India" && <Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id="price_1L65g8SJsVlrtsJgHOxyXDAV" onClick={checkOut}>Upgrade Now</Button>}
                        {state.countryName !== "India" &&<Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id="price_1L5jIvSJsVlrtsJgwOkuXJLs" onClick={checkOut}>Upgrade Now</Button>} */}
                        {state.countryName == "India" && <Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id={planObjYear[selectedPlan].planPriceID} onClick={checkOut}>Upgrade Now</Button>}
                        {state.countryName !== "India" &&<Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id={planObjYearUs[selectedPlan].planPriceID} onClick={checkOut}>Upgrade Now</Button>}
                         
                          </div>
                        <div className="card-text my-3">
                                <ul> 
                                    <li><i className="tickMark">✔</i> <strong>750 credits</strong></li>
                                    <li><i className="tickMark">✔</i>Access to all tools</li>
                                    <li><i className="tickMark">✔</i>1 credit is 1 image</li> 
                                    <li><i className="tickMark">✔</i>Image History</li>
                                    <li><i className="tickMark">✔</i>AI Upscaling to 4x</li>
                                    <li><i className="tickMark">✔</i> 24/7 email support</li>
                                    <li><i className="tickMark">✔</i>GPU enabled/fast generation</li> 
                                </ul> 
                            </div>
                    </div>
                </div>
            </div> 
      <div className="col-md-4">
                <div className="card pricing-box d-flex mx-auto activebox">
                    <div className="card-body">
                    <div className="offerTxt">40 % off</div>
                        <h3 className="card-title">Professional</h3>
                        <h5 className="card-subtitle mb-1">
                        {state.countryName == "India" && <>&#8377;1499 
                        <span className="ml-3"> Per month. Billed &#8377;17988 yearly.</span>
                        </>}
                        {state.countryName !== "India" && <>&#36;19 
                        <span className="ml-3"> Per month. Billed &#36;228 yearly.</span>
                        </>}
                            </h5> 
                           {/* <small className="text-muted">Cancel anytime. Billed yearly.</small> */} 
                           <div className="planWords mt-2">
                            <strong>1500</strong> credits
                          </div> 
                        <div>
                        {/* {state.countryName == "India" && <Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id="price_1L65g8SJsVlrtsJgHOxyXDAV" onClick={checkOut}>Upgrade Now</Button>}
                        {state.countryName !== "India" &&<Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id="price_1L5jIvSJsVlrtsJgwOkuXJLs" onClick={checkOut}>Upgrade Now</Button>} */}
                        {state.countryName == "India" && <Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id={planObjYear[selectedPlan].planPriceID} onClick={checkOut}>Upgrade Now</Button>}
                        {state.countryName !== "India" &&<Button variant="primary" disabled={loading} size="md" className="w-100 buttonWrap" id={planObjYearUs[selectedPlan].planPriceID} onClick={checkOut}>Upgrade Now</Button>}
                         
                          </div>
                        <div className="card-text my-3">
                                <ul> 
                                    <li><i className="tickMark">✔</i> <strong>1500 credits</strong></li>
                                    <li><i className="tickMark">✔</i>Access to all tools</li>
                                    <li><i className="tickMark">✔</i>1 credit is 1 image</li> 
                                    <li><i className="tickMark">✔</i>Image History</li>
                                    <li><i className="tickMark">✔</i>AI Upscaling to 4x</li>
                                    <li><i className="tickMark">✔</i> 24/7 email support</li>
                                    <li><i className="tickMark">✔</i>GPU enabled/fast generation</li> 
                                </ul> 
                            </div>
                    </div>
                </div>
            </div> 
            <div className="col-md-4">
                <div className="card pricing-box d-flex mx-auto enterprise">
                <div className="card-body">
                        <h3 className="card-title">Enterprise</h3>
                        <h5 className="card-subtitle-custom mb-1">
                        Customized </h5> 
                        <div> 
                        <div className="planWords mt-2 mb-2">
                          Yearly package</div>
                        <div className="planWords mt-2 mb-3"><strong><i>Looking for unlimited credits?</i></strong></div>
                       <Button variant="primary-outline" disabled={loading} size="md" className="w-100 buttonWrap" target="_blank" href="mailto:info.imagekraft@gmail.com">Get in touch</Button>
                         </div>
                        <div className="card-text my-3">
                                <ul> 
                                    <li><i className="tickMark">✔</i> Includes professional plan</li> 
                                    <li><i className="tickMark">✔</i> <strong className="mr-1">Unlimited</strong> credits</li>                                   
                                    <li><i className="tickMark">✔</i>Access to all tools</li>
                                    <li><i className="tickMark">✔</i>Customised tool</li>  
                                    <li><i className="tickMark">✔</i>Priority 24/7 support</li>
                                    <li><i className="tickMark">✔</i>GPU enabled/ultra fast generation</li> 
                                </ul>
                            </div> 
                    </div>
                </div>
            </div> 
           </div>
      </Tab> 
    </Tabs>


          

        <div className="row mt-4 faqs">
            <div className="col-md-12">
                <div className="my-4 text-center">
                <h3>FAQ's</h3>
                <div>Frequently asked questions, answered.</div>
                </div>
            <Accordion defaultActiveKey="0" flush >
  <Accordion.Item eventKey="0" className="rounded mb-3">
    <Accordion.Header>What is a credit in ImageKraft AI’s pricing plans?</Accordion.Header>
    <Accordion.Body>
    A credit is equivalent to one image generated using our AI image generation technology. Each plan has a certain number of credits included, and additional credits can be purchased as needed.
   </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="1" className="rounded mb-3">
    <Accordion.Header>Can I use the images generated with ImageKraft AI for commercial purposes?</Accordion.Header>
    <Accordion.Body>
    Yes, you can use the images generated with ImageKraft AI for commercial purposes, provided you comply with our terms of service and the licensing terms of any third-party assets used in the image.
  </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="2" className="rounded mb-3">
    <Accordion.Header>Can I upgrade or downgrade my plan at any time?</Accordion.Header>
    <Accordion.Body>
    Yes, you can upgrade or downgrade your plan at any time, and the changes will take effect immediately. If you upgrade, you will be charged the difference in price between your current plan and the new plan. If you downgrade, any unused credits will be carried over to the new plan.
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="3" className="rounded mb-3">
    <Accordion.Header>Can I cancel my subscription anytime?</Accordion.Header>
    <Accordion.Body>
    Yes, you can cancel your subscription at any time. However, please note that refunds are not provided for unused credits.
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="4" className="rounded mb-3">
    <Accordion.Header>What is the difference between the Professional and Enterprise plans?</Accordion.Header>
    <Accordion.Body>
    The Professional plan provides access to a set number of credits with upscaling and 24/7 support, while the Enterprise plan offers customizable plans with unlimited credits, access to all tools, priority support, and GPU-enabled ultra fast generation.
    </Accordion.Body>
  </Accordion.Item> 
</Accordion>
            </div>
        </div>
 
          </div>
        </div> 
      <Toaster position="top-center" reverseOrder={false} />
      
      <Modal show={showModal} onHide={handleClose} keyboard={false} className={loginState ? "loginState" : ""}> 
          {loginState && <Modal.Body className="text-center">   
             <InlineLogin onLoginSuccess={handleLoginSuccess}/>
          </Modal.Body>} 
      </Modal> 
    </>
  );
};

export default Pricing;