import {React, useState, useEffect, useContext} from "react";
import {Button, Card, Container, NavLink } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast'; 
import { useUserAuth } from "../../../context/UserAuthContext";
import { SubscriberContext } from "../../../context/subscriberContext";  
import CommonDataServices from "../../../services/common.services";
import { getFunctions, httpsCallable } from "firebase/functions";  
import Modal from 'react-bootstrap/Modal';

    import "./Profile.css"; 
    import ProgressBar from 'react-bootstrap/ProgressBar'
    import {
      Chart as ChartJS,
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend,
    } from 'chart.js';
    import { Bar } from 'react-chartjs-2';
import Loader from "../../shared/utilities/loader/Loader";

    ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend
    );

    export const options = {
      responsive: true,
      plugins: {         
        title: {
          display: true,
          text: 'Daily usage',
        },
      },
    };
     
const Profile = () => { 
  const {subscriber, setSubscriber} = useContext(SubscriberContext);
  const { user } = useUserAuth();
  const navigate = useNavigate(); 
  const functions = getFunctions();  
  const [usagePercentage, setUsagePercentage] = useState(0);
  const [subscription, setSubscription] = useState(null);   
  const [subState, setSubState] = useState(false)
  const [alertMsg, setAlertMsg] = useState()  
  const [showModal, setShowModal] = useState(false); 
  const handleShow = () => setShowModal(true);
  const [modalData, setModalData] = useState({})

  //['1 Jan', '2 Jan', '3 Jan', '4 Jan', '5 Jan', '6 Jan', '7 Jan']
  //['20', '50', '60', '20', '30', '12', '60'] 
  const [barData, setBarData] = useState({
                          labels: [],
                          datasets: [
                            {
                              label: 'Usage',
                              barPercentage: 0.5,
                              barThickness: 8,
                              maxBarThickness: 8,
                              minBarLength: 2,
                              data: [],
                              backgroundColor: 'rgb(84 42 223)',
                            }
                          ],
                        });
   
  useEffect(() => { 
    getDailyUsage(); 
    //getSubscription();
  }, [])

  useEffect(()=>{
    const now = (subscriber.totalCredits / subscriber.creditsLimit) * 100;
   // //console.log(now)  
    setUsagePercentage(Math.round(now))


    // function converDate (cDate){
    //   //var timestamp = usageData.planStartDate
    //   var timestamp = cDate
    //   var date = new Date(timestamp);
    //   //console.log(date.getTime())
    //   //console.log(date)  
    //   return date.toLocaleDateString()
    // }
    // if(usageData){   
    //   //console.log('convert date')
    //   setUsageData({...usageData, planStartDate: converDate(usageData.planStartDate)})
    // }


  }, [subscriber])

 

  const getDailyUsage = async () => {  
    const usedata = await CommonDataServices.getDailyUsageDocs(user.uid);
    //console.log(usedata); 
    let dateArry = [];
    let useageArry = []; 

    usedata.docs.forEach(doc => {  
        dateArry.push(doc.data().date)
        useageArry.push(String(doc.data().count))  
    })  
    if(dateArry.length > 1){   
        setBarData({labels: dateArry, datasets:[{label: 'Credits', maxBarThickness: 8, backgroundColor: 'rgb(84 42 223)', data:useageArry}]}) 
      }
     //console.log(barData)
  }
  const getSubscriptionData = async (e) => {
    const subId = e.target.id;     
    const getSub = await httpsCallable(functions, 'getStripeSubscription'); 
    const param = {  subId : subId   }
    getSub(param).then((response) => {
      //console.log(response) 
    })  
  }
  const handleClose = (e) => {
    setShowModal(false);    
 }
  const cancelsubscriptionModal = () =>{ 
      setShowModal(true);
  }

  const cancelSubscription = (e) => {
    //console.log("oop... your subscription canceled")
    const subId = e.target.id;    //"sub_1M1bSDSJsVlrtsJgy6wojsmJ"
    try{ 
      setSubState(true)
      const delSub = httpsCallable(functions, 'cancelStripeSubscription');
      const param = { subId : subId, userId: user.uid}

      setShowModal(false); 

      delSub(param).then(async (response) => {
        //console.log("check response...")
        //console.log(response)
        if(response){
          //console.log("check response...2")
          setSubscriber(subscriber => ({...subscriber, cancelAtPeriodEnd: true}))
          setSubState(false)
        } else{
          setSubState(false)
          setAlertMsg("Please contact support");
        }
        //const usedata = await CommonDataServices.getSubscriptionData(user.uid);
        //console.log(usedata.data()) 
        //setSubscriber(usedata.data())  
      })  
    } catch (error) {
      //console.log(error.message);
      setSubState(false)
      setShowModal(false); 
    }
  } 
  const reactiveSubscription = (e) => {
    const subId = e.target.id;    
    setSubState(true) 
    try{
    const activeSub = httpsCallable(functions, 'reactiveStripeSubscription');
    const param = {
      subId : subId, //"sub_1M1bSDSJsVlrtsJgy6wojsmJ",
      userId: user.uid
    } 
    activeSub(param).then((response) => {
      //console.log(response)
      setSubState(false)
      setSubscriber(subscriber => ({...subscriber, cancelAtPeriodEnd: false}))
    }) 
  } catch (error) {
    //console.log(error.message);
    setSubState(false)
  }
  }
  
  return (
    <>   
      <div className="main-layout profile page-layout">
               
                <div className="header-txt text-center"> 
                    <h2>Profile</h2> 
                    <p>Check your Account information, Billing details and Usage stats. </p>
               </div> 
               <div className="container justify-content-center">
            <div className="row">
                <div className="col-sm-9 mx-auto">
    <h5 className="pb-2">Account Information</h5>
                    <Card className="mb-5"> 
                      <Card.Body>
                      <div className="wrapper">
     
    <div className="py-4">
        <div className="row">
        <div className="col-md-2"> 
        {/* <img src={user.photoURL} alt="" className="img w-100 mt-2 rounded"/>   */}
        <div className="img w-100 mt-2 rounded profileImage" style={{backgroundImage:`url(${user.photoURL})`}}></div>
        </div>
        <div className="col-md-10"> 
       
        <div className="mb-4"> <label htmlFor="fullname">Full Name</label> <input type="text" className="form-control" placeholder="Steve" defaultValue={user.displayName} readOnly/> </div> 
            <div className="mb-4"> <label htmlFor="email">Email Address</label> <input type="text" className="form-control" placeholder="steve_@email.com" defaultValue={user.email} readOnly/> </div>
           
        </div>
        </div> 
    </div>
</div>
                      </Card.Body>
                      </Card>
                      <h5 className="pb-2">Billing Details</h5>
                      <Card className="mb-5"> 
                      <Card.Body>
                      <div className="wrapper billing">
   
    {subscriber && <>
    {subscriber.planType !== "free" && 
    <div className="py-4">  
            <div className="subHead">
            <h6>Active Plan  </h6>
            <p>Your current active plan details</p>
            </div>
            <div className="row">
              <div className="col"><p><strong>Plan:</strong><br/> {subscriber.planType} - <i>Monthly</i></p></div>
              <div className="col"> <p><strong>Plan status:</strong><br/> Active</p></div>
              <div className="col"><p><strong>Amount paid:</strong><br/> 
               {subscriber.paidAmount} 
              </p></div>
              <div className="col"><p><strong>Start date:</strong><br/> {new Date(subscriber.planStartDate * 1000).toLocaleDateString('en-us',{day:'numeric', month:'short', year:'numeric'})}</p></div> 
              <div className="col"><p><strong>Next renewal:</strong><br/> {new Date(subscriber.planEndDate * 1000).toLocaleDateString('en-us',{day:'numeric', month:'short', year:'numeric'})} </p></div>
             </div>
             <div className="row">
               <div className="col">
                  <div className="float-left"> 
                  {!subscriber.cancelAtPeriodEnd && <>
                    <p><Button disabled={subState} variant="outline-secondary" size="sm" id={subscriber.current} onClick={cancelsubscriptionModal}>Cancel Subscription</Button></p>
                  </>}
                  {subscriber.cancelAtPeriodEnd && <>
                    <p>Your subscription cancelled.</p>
                    <p><Button disabled={subState} variant="outline-primary" size="sm" id={subscriber.current} onClick={reactiveSubscription}>Reactivate Subscription</Button></p>
                
                  </>} 
                    </div>  
                    <div className="float-right loaderStyle"> 
                        {subState && <Loader />}
                     </div>    
               </div>
             </div>
        </div> }
    <div className="py-4">  
    <div className="subHead">
            <h6 className="text-capitalize"> 
             {subscriber.planType} {subscriber.planType == "free" && <>trial</>} usage</h6>
            <p>You are currently on {subscriber.planType} {subscriber.planType == "free" && <>trial</>}. </p>
            </div> 
         
        
                 {subscriber && (subscriber.creditsLimit < 380000) &&
                <div className="row">
                  <div className="col">
                <p className="mb-2">Check the available credits on your plan.</p>
                 <ProgressBar now={usagePercentage} label={`${usagePercentage} %`} /> 
                 <p className="text-right m-0">{subscriber && subscriber.totalCredits} / {subscriber && subscriber.creditsLimit} Credits</p> 
               </div> 
               </div>
                }
                    {(subscriber.planType == "free" || subscriber.totalCredits > subscriber.creditsLimit) &&
                <div className="row mt-4">
                    <Button variant="primary mt-3 px-3" size="sm" className="upBtn" onClick={()=> navigate(`/pricing`)}>Upgrade</Button>
                </div> 
                 }
            </div>
            {/* {subscriber.planType !== "free" &&   <div className="row"> 
            <p><Button variant="outline-secondary" size="sm" id={subscriber.current} onClick={getSubscriptionData}>Get Subscription</Button></p>
            </div>} */}
        </> }
</div>
                      </Card.Body>
                      </Card>
                      <h5 className="pb-2">Usage Stats</h5>
                      <Card className="mb-5"> 
                      <Card.Body>
                      <div className="wrapper">
   
    <div className="py-4">  
            <div> 
           {barData && <Bar options={options} data={barData} size={22}/>}  
            </div> 
            {/* <pre>{ JSON.stringify(days, undefined, 2)}</pre> 
            <pre>{ JSON.stringify(usage, undefined, 2)}</pre>  */}
            {/* <pre>{ JSON.stringify(subscriber, undefined, 2)}</pre>  */}
    </div>
</div>
                      </Card.Body>
                    </Card>

                    <Card className="mb-5"> 
                      <Card.Body>
                    <div className="d-sm-flex align-items-center py-4" id="deactivate">
            <div> <b>Delete account</b>
                <p>This account will no longer be accessible. All your saved data will be permanently deleted</p>
            </div>
            <div className="ml-auto"> <button className="btn danger">Delete</button> </div>
        </div>
        </Card.Body>
                    </Card>

                </div>
               </div>
               </div>
        </div> 
      <Toaster position="top-center" reverseOrder={false} />

      
 
      <Modal
        show={showModal}
        onHide={handleClose} 
        keyboard={false}
        className="cancelSubscriptionModal"
      >  
        <Modal.Body className="text-center">
              <h4>Cancel Subscription?</h4>
              <p>Your subscription will be cancelled at the end of your billing period. You can change your mind any time before this date.</p>  
          <div className="text-center">  
       
            <Button variant="secondary" size="md" className="mr-4" onClick={handleClose}> Not Now </Button>
            <Button variant="primary" size="md" onClick={cancelSubscription} >Confirm</Button> 
         
         </div>
        </Modal.Body> 
      </Modal> 
    </>
  );
};

export default Profile;