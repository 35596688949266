const PersonalData = { 
personalStatement : {
title:"Personal statement",
caption:"This AI template can help you write a personal statement for your resume.",
category:"personal",
inputs :`Reason for applying: [write the reason for  choosing the program]
How you are good fit for the program: [write here..]
Long-term goals: [write here..]
Relevant academic: [write here..]
Personal achievements: [write here..]
Relevant work or volunteer experience: [write here..]
Obstacles you have overcome in your life: [write here..]`
}, 
professionalBio : {
title:"Professional bio",
caption:"This AI template can help you write a professional bio for your resume.",
category:"personal",
inputs :`Name: [your name]
Age: [write here..]
Gender: [write here..]
Location: [write here..]
Occupation: [write here..]
Experience: [write here..]
Qualification: [write here..]
Skills: [write here..]
Achievements: [write here..]
Interests: [write here..]`
},   
statementOfPurpose : {
title:"Statement of purpose",
caption:"This AI template can help you write the best impressive statement of purpose.",
category:"personal",
inputs :`Name: [Your name]
Education: [Undergraduate/Postgraduate]
Purpose of taking the program: [writ purpose of taking this program/opportunites]
Academic: [your academic journey and accomplishments] 
Extracurricular activities: [write extracurricular activities]
Aspirations: [your career aspirations]
Undertaken assignments: [if any]
Internship if any: [if any]

Format:
Start with introduction 
Write 500 words`
},
getWellSoonLetter : {
title:"Get well soon letter",
caption:"This AI template can help you write a warm get well soon letter.",
category:"personal",
inputs :`To: [recipient name]
From:[your name]`
},
goodbyeLetter : {
title:"Goodbye letter",
caption:"This AI template can help you write a goodbye letter to your team.",
category:"personal",
inputs :`To: [recipient name]
From: [sender name]
Mention specific moments that were memorable:[write here..]`
},
celebrationLetter : {
title:"Celebration letter",
caption:"This AI template can help you write a celebration letter.",
category:"personal",
inputs :`Event name: [write here..]
Date of the event: [write here..]
Significance of the event: [write here..]
Who made the event: [write here..]
Benefits of the event: [write here..]`
},
personalLetter : {
title:"Personal letter",
caption:"This AI template can help you write the besteveer personal letter.",
category:"personal",
inputs :`To: [write here..]
Reason you are writing: [write here..]
What you hope to accomplish with the letter: [write here..]`
},
anniversayLetter : {
title:"Anniversary letter",
caption:"This AI template can help you write an anniversary letter to your employees.",
category:"personal",
inputs :`Date and Years: [write here..]
How long  been together: [write here..]
What accomplished together: [write here..] 
What means to each other: [write here..] 
What the future holds: [write here..]`
},
congratulationLetter : {
title:"Congratulation letter",
caption:"This AI template can help you write a congratulation letter to your team.",
category:"personal",
inputs :`Reason: [write here..]
Person or team: [write here..]
What they did: [write here..]
A mention of future success`
},
grievanceLetter : {
title:"Grievance letter",
caption:"This AI template can help you write a grievance letter.",
category:"personal",
inputs :`Problem or issue: [write here..]
How this affecting you: [write here..]
What you try to resolve the problem or issue:[write here..]
Who you have contacted:[write here..]
What you would like to see happen to resolve the problem:[write here..]`
},
condolenceLetter : {
title:"Condolence letter",
caption:"This AI template can help you write a condolence letter.",
category:"personal",
inputs :`To:[write here..]
Cause of death:[write here..]
Positive qualities of deceased person:[write here..]
Expressions of sympathy 
A memory of the deceased person 
An offer of help 
Closing thoughts`
},
apologyLetter : {
title:"Apology letter",
caption:"This AI template will help you to create a successful marketing strategy.",
category:"personal",
inputs :`To:[write here..]
Why this apology: [write here..]`
}, 
leaveLetter : {
title:"Leave letter",
caption:"This AI template can help you write a leave letter to boss.",
category:"personal",
inputs :`To: [recipient name]
Reason for the leave:[write here..]
Dates of the leave:[write here..]`
}, 
holidaysLetter : {
title:"Holidays letter",
caption:"This AI template will help you to create a best holidays letter.",
category:"personal",
inputs :`To: [recipient name]
Where you went:[write here..]
What you did:[write here..]
Who you were with:[write here..]
What you thought of the experience:[write here..]`
}     
}
export default PersonalData;