import { db } from "../firebase";
import { serverTimestamp } from "firebase/firestore";  

import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  doc,
  setDoc, 
  updateDoc,
  limit,
  deleteDoc,
  lastDoc,
  query,
  startAfter 
} from "firebase/firestore";  
//const uid = localStorage.getItem("user"); 
//console.log(uid);

class CommonDataServices {  
 
  state = {
    language: "en",
    setLanguage: this.setLanguage
  };

  setUserSubscription = (uid) => { 
    //oneMonth
    // var oneMonthDate = new Date(); 
    // oneMonthDate.setMonth(oneMonthDate.getMonth() + 1); 
    // //console.log(oneMonthDate);

    let currentDate = Math.floor(Date.now() / 1000)
    let oneMonth = new Date();
    let expDate  = Math.round(oneMonth.setMonth(oneMonth.getMonth()+1) / 1000);

    const newSub = {
      userId: uid, 
      planType: "free",
      planStartDate: currentDate,
      planEndDate: expDate,
      premiumState: true,
      paidAmount: 0,
      totalCredits: 0,
      creditsLimit: 100 
    }
    const userRef = doc(db, `userData/${uid}/`); 
    return setDoc(userRef, newSub); 
  }
  updateUserSubscription = (uid, subObj) => {    
    //console.log(subObj)
    const userRef = doc(db, `userData/${uid}/`); 
    return updateDoc(userRef, subObj); 
  }
  updateEndDate = (uid, endDate) => { 
    const userRef = doc(db, `userData/${uid}/`); 
    return updateDoc(userRef, {planEndDate: endDate})
  }

  getTotalUsageData = (uid) => {
        const projectSecRef = doc(db, `userData/${uid}`); 
        return getDoc(projectSecRef);  
   }
  getDailyUsageDocs = (uid) => {
    const collRef = collection(db, `userData/${uid}/usageData`); 
    return getDocs(collRef);  
  }
  getSubscriptionData = (uid) => {
    const docRef = doc(db, `subscription/${uid}`); 
    return getDoc(docRef); 
  } 
  getDayUsageData = (uid, dateID) => {
    //console.log(dateID) 
    const projectSecRef = doc(db, `userData/${uid}/usageData/${dateID}`); 
    return getDoc(projectSecRef);  
  }
  getUsageLimit = (uid) => {
    const projectSecRef = doc(db, `userData/${uid}`); 
    return getDoc(projectSecRef);  
  }

  usageTrackingData = async (uid, imgCredit) => {
    //console.log(response)
    let imgCount = imgCredit;
    console.log(imgCount)
    if(imgCount){
        //console.log(wordCount)    
        const date = new Date();
        const dateID = date.toLocaleDateString('en-GB').split('/').reverse().join('');  
        //console.log(dateID)
        const dayRef = doc(db, `userData/${uid}/usageData/${dateID}/`); 
        const totalRef = doc(db, `userData/${uid}/`); 
        let dayCount = 0;
        //get Usage count
        await this.getDayUsageData(uid, dateID).then((resp) =>{ 
          const result = resp.data();
          if(result){
            dayCount = resp.data().count;
            //console.log(result)
          } else {
            dayCount = undefined;
          }
        }); 
        const getTotalCount = await this.getTotalUsageData(uid, dateID);
        const totalCount = getTotalCount.data().totalCredits;
        const fixedwordLimit = getTotalCount.data().creditsLimit
        
        if(dayCount){   
          let countUpdate = imgCount + dayCount;
          let totalUsage = imgCount + totalCount; 
                 updateDoc(totalRef, {totalCredits: totalUsage, creditsLimit: fixedwordLimit}); 
          return setDoc(dayRef, {count: countUpdate, date: new Date().toDateString()});  
        } else{   
          let totalUsage = imgCount + totalCount;
                 updateDoc(totalRef, {totalCredits: totalUsage, creditsLimit: fixedwordLimit}); 
          return setDoc(dayRef, {count: imgCount, date: new Date().toDateString()}); 
        } 
    }  
  } 

  imageUsageTrackingData = async (uid, imgCredits) => {
    //console.log(response)
    let wordCount = imgCredits;
    //console.log(wordCount)
    if(wordCount){
        //console.log(wordCount)    
        const date = new Date();
        const dateID = date.toLocaleDateString('en-GB').split('/').reverse().join('');  
        //console.log(dateID)
        const dayRef = doc(db, `userData/${uid}/usageData/${dateID}/`); 
        const totalRef = doc(db, `userData/${uid}/`); 
        let dayCount = 0;
        //get Usage count
        await this.getDayUsageData(uid, dateID).then((resp) =>{ 
          const result = resp.data();
          if(result){
            dayCount = resp.data().count;
            //console.log(result)
          } else {
            dayCount = undefined;
          }
        }); 
        const getTotalCount = await this.getTotalUsageData(uid, dateID);
        const totalCount = getTotalCount.data().totalCredits;
        const fixedwordLimit = getTotalCount.data().creditsLimit
        
        if(dayCount){   
          let countUpdate = wordCount + dayCount;
          let totalUsage = wordCount + totalCount; 
                 updateDoc(totalRef, {totalCredits: totalUsage, creditsLimit: fixedwordLimit}); 
          return setDoc(dayRef, {count: countUpdate, date: new Date().toDateString()});  
        } else{   
          let totalUsage = wordCount + totalCount;
                 updateDoc(totalRef, {totalCredits: totalUsage, creditsLimit: fixedwordLimit}); 
          return setDoc(dayRef, {count: wordCount, date: new Date().toDateString()}); 
        } 
    }  
  } 

   
  

  addHistory = (uid, newProject) => {
    //console.log(uid)
    // console.log(newProject)    
    // const projectRef = collection(db, `history/${uid}/`)
    // return addDoc(projectRef, newProject);  
    const galleryRef = collection(db, `gallery/`)
    addDoc(galleryRef, newProject)

    const projectRef = collection(db, `history/${uid}/images/`)
    return addDoc(projectRef, newProject);  
  } 
  getGallery = async (uid, lastDoc) => {    
    const projectRef = collection(db, `gallery/`);
    let limitedProjectRef = query(projectRef, limit(15)); 
    if (lastDoc) {
    limitedProjectRef = query(limitedProjectRef, startAfter(lastDoc));
    } 
    const querySnapshot = await getDocs(limitedProjectRef);
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    //console.log(lastVisible);
    return { docs: querySnapshot.docs, lastDoc: lastVisible };
  } 
  getHistory = async (uid, lastDoc) => {   
    // const projectRef = collection(db, `history/${uid}/images/`)  
    // const limitedProjectRef = query(projectRef, limit(5));
    // return getDocs(limitedProjectRef); 
   // let lastDoc = null;
    const projectRef = collection(db, `history/${uid}/images/`);
    let limitedProjectRef = query(projectRef, limit(15));
  
    if (lastDoc) {
    limitedProjectRef = query(limitedProjectRef, startAfter(lastDoc));
    }

    const querySnapshot = await getDocs(limitedProjectRef);
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
  //  console.log(lastVisible);
    return { docs: querySnapshot.docs, lastDoc: lastVisible };
  } 
  setPublishImg = (docId, publishState) => { 
    const docRef = doc(db, `gallery/${docId}/`); 
    return updateDoc(docRef, {publish: publishState})
  }
  setDeleteImg = (docId) => { 
    const docRef = doc(db, `gallery/${docId}`); 
    return deleteDoc(docRef)
  }
  generatePrompt = (uid) => {  
    const projectRef = collection(db, `history/${uid}/images/`)
    return getDocs(projectRef); 
  }   
  
  sendWordCount = (response) => { 
    let wordCount = 0; 
    response.data.choices.forEach((doc) => {
        wordCount = wordCount + parseInt(doc.text.split(' ').filter(word => word !== '').length);   
    }) 
    if(wordCount > 1){ 
      //console.log(wordCount)
      return wordCount;
    }  
 }

}

export default new CommonDataServices();

 