import {React, useState, useEffect, useContext} from "react"; 
import "@stripe/stripe-js";
import { Routes, Route } from "react-router-dom";
import "./App.css";

import Dashboard from "./components/dashboard/Dashboard";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Reset from "./components/Reset";
import Header from './components/ui/header/Header'; 
import Pricing from "./components/views/Pricing";
import Landing from "./components/views/Landing";
import Profile from "./components/views/settings/Profile";
import ProtectedRoute from "./components/ProtectedRoute"; 
import commonDataServices from "./services/common.services"; 
import { SubscriberContext } from "./context/subscriberContext"; 
import { useUserAuth } from "./context/UserAuthContext";    
import VerifyEmail from "./components/VerifyEmail"; 
import { getFunctions, httpsCallable } from "firebase/functions"; 
import Success from "./components/views/Success";
import Cancel from "./components/views/Cancel";
import NotFound from "./components/views/NotFound"; 
import Paraphrase from "./components/tools/paraphrase/Paraphrase"; 
import Templates from "./components/dashboard/templates/Templates"; 
import WebUI from "./components/tools/web-ui/WebUI";
import StockImage from "./components/tools/stock-image/StockImage";
import BookCover from "./components/tools/book-cover/BookCover";
import Wallpaper from "./components/tools/wallpaper/Wallpaper";
import AppIcon from "./components/tools/app-icon/AppIcon";
import Illustration from "./components/tools/illustration/Illustration";
import Poster from "./components/tools/poster/Poster";
import Architecture from "./components/tools/architecture/Architecture";
import InteriorDesign from "./components/tools/interior-design/InteriorDesign";
import Fashion from "./components/tools/fashion/Fashion";
import Anime from "./components/tools/anime/Anime";
import Gallery from "./components/dashboard/gallery/Gallery";
import History from "./components/dashboard/history/History";
import BlogBanner from "./components/tools/blog-banner/BlogBanner";
import InlineLogin from "./components/InlineLogin";

function App() { 
  const { user} = useUserAuth();
  const functions = getFunctions(); 
  //if(user){ localStorage.setItem("user", user.uid)}   
  const [subscriber, setSubscriber] = useState(false);
  const userSub = { subscriber, setSubscriber };     

  useEffect( async () => {    
      if(user){
        //console.log(user) 
        const subData = await commonDataServices.getUsageLimit(user.uid);
        //console.log(subData.data())
        if(subData.data()){  
          setSubscriber(subData.data())
          let userData = subData.data();
         // //console.log(userData) 
          //Logic to reset the totalwords on every 30 days
          let currentDate = Math.floor(Date.now() / 1000);
          if(userData.planType == "free"){
            if(currentDate > userData.planEndDate){ 
              let oneMonth = new Date();
              let expDate  = Math.round(oneMonth.setMonth(oneMonth.getMonth()+1) / 1000); 
              let subObject = { 
                planStartDate: currentDate, 
                planEndDate: expDate, 
                totalCredits: 0,
                creditsLimit: 100
                } 
                await commonDataServices.updateUserSubscription(user.uid, subObject).then(async (resp) => {
                  const subData = await commonDataServices.getUsageLimit(user.uid); 
                  setSubscriber(subData.data()) 
                }); 
            } 
          }
          if(userData.planType != "free"){
             //console.log(userData)  
             if(currentDate > userData.planEndDate){ 
                //console.log(currentDate)   
                const subId = userData.current; //userData.current; // Get sub_id from local  "sub_1LooVxSJsVlrtsJgye5nkeXP"
                if(subId){ //if user had sub_id
                  const getSub = await httpsCallable(functions, 'getStripeSubscription'); 
                //  //console.log(subId)
                  const param = {  subId : subId   }
                  getSub(param).then(async (response) => {
                   // //console.log(response) 
                    let subRes = response.data; //get user sub data
                   // //console.log(subRes.status)
                    //console.log(subRes.current_period_end)
                    if(subRes.current_period_end > currentDate && subRes.status == "active"){ //check sub_end date is grater than current date (if he paid it will updates the end date to new date)
                      //console.log("Active renewal..")
                      let subObject = { 
                        planStartDate: subRes.current_period_start, 
                        planEndDate: subRes.current_period_end, 
                        renewalDate: subRes.current_period_end,
                        totalCredits: 0 
                        } 
                        await commonDataServices.updateUserSubscription(user.uid, subObject).then(async (resp) => {
                          const subData = await commonDataServices.getUsageLimit(user.uid);
                          //console.log('Renewal done..')
                          //console.log(subData.data()) 
                          setSubscriber(subData.data()) 
                        }); 
                    } else{
                      //console.log("Past_due expired..")  
                    }
                  }) 
                }   
             }
          }
        } else{  
          await commonDataServices.setUserSubscription(user.uid).then(async (resp) => {
            const subData = await commonDataServices.getUsageLimit(user.uid);
            //console.log(subData.data()) 
            setSubscriber(subData.data()) 
          }); 
        }   
      }
   },[user])  

  return (    
       <SubscriberContext.Provider value={userSub}>
       {/* {user && user.emailVerified && <Header/> }<ProtectedRoute>  */}
       <Header/>
      <Routes>  
        <Route path="/" element={ <StockImage /> } />

        <Route path="/paraphrase" element={ <ProtectedRoute> <Paraphrase /> </ProtectedRoute> } />
        <Route path="/paraphrase/:prompt" element={ <ProtectedRoute> <Paraphrase /> </ProtectedRoute> } /> 

        <Route path="/stock-image" element={ <StockImage/> } />
        <Route path="/stock-image/:prompt" element={<StockImage/> } /> 

        <Route path="/book-cover" element={ <BookCover/>  } />
        <Route path="/book-cover/:prompt" element={ <BookCover/> } />
        
        <Route path="/wallpaper" element={  <Wallpaper/> } />
        <Route path="/wallpaper/:prompt" element={ <Wallpaper/> } />
        
        <Route path="/blog-banner" element={ <BlogBanner/>  } />
        <Route path="/blog-banner/:prompt" element={ <BlogBanner/> } />
        
        <Route path="/app-icon" element={  <AppIcon/>  } />
        <Route path="/app-icon/:prompt" element={ <AppIcon /> } /> 
        
        <Route path="/illustration" element={  <Illustration/> } />
        <Route path="/illustration/:prompt" element={  <Illustration />} /> 
        
        <Route path="/poster" element={ <Poster/> } />
        <Route path="/poster/:prompt" element={ <Poster /> } /> 

        <Route path="/web-ui" element={  <WebUI />  } />
        <Route path="/web-ui/:prompt" element={  <WebUI /> } /> 

        <Route path="/architecture" element={ <Architecture/> } />
        <Route path="/architecture/:prompt" element={  <Architecture />  } /> 
        <Route path="/interior-design" element={<InteriorDesign/> } />
        <Route path="/interior-design/:prompt" element={ <InteriorDesign />} /> 
        <Route path="/fashion" element={  <Fashion/>} />
        <Route path="/fashion/:prompt" element={ <Fashion />} /> 
        <Route path="/anime" element={  <Anime/>} />
        <Route path="/anime/:prompt" element={ <Anime />} /> 

        <Route path="/stock-image" element={ <Dashboard />  } />
        <Route path="/templates" element={ <ProtectedRoute> <Templates/> </ProtectedRoute> } /> 
 
        <Route path="/profile" element={ <ProtectedRoute> <Profile />  </ProtectedRoute> } />
        <Route path="/pricing" element={ <Pricing />  } />
        <Route path="/gallery" element={ <ProtectedRoute> <Gallery/> </ProtectedRoute> } />
        <Route path="/my-creations" element={ <ProtectedRoute> <History/>  </ProtectedRoute> } />
        <Route path="/login" element={<Login />} />
        <Route path="/login/:type" element={<Login />} />
        <Route path="/Inlinelogin" element={<InlineLogin />} />
        <Route path="/Inlinelogin/:type" element={ <InlineLogin/>} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/reset" element={<Reset />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/success/" element={<Success />} />
        <Route path="/success/:id" element={<Success />} />
        <Route path="/cancel" element={<Cancel />} />
        <Route path="*" element={ <NotFound/> } />
      </Routes>
       </SubscriberContext.Provider>  
  );
}

export default App;