import HRData from "./HRData";
import CareersData from "./CareersData";
import EmailsData from "./EmailsData";
import BusinessData from "./BusinessData";
import MarketingData from "./MarketingData";
import PersonalData from "./PersonalData";
const TemplatesData = { 
...BusinessData,
...MarketingData,
...CareersData,
...HRData,
...EmailsData,  
...PersonalData                                                                                                                                                                                                                                                                                                                    
}


export default TemplatesData;