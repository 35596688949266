import './loginForm.css' 
import {useState, useEffect} from 'react'
import {auth} from '../firebase'
import {sendEmailVerification} from 'firebase/auth'
import {useUserAuth } from "../context/UserAuthContext"; 
import {useNavigate, Link} from 'react-router-dom'  
import { Button, Card } from "react-bootstrap"; 
import { LogoSvg } from '../assets/svg/SvgIcons';

function VerifyEmail() {

  const { user } = useUserAuth();  
  const navigate = useNavigate()

  useEffect(() => {
    const interval = setInterval(() => {
        user?.reload()
      .then(() => {
          //console.log(user.emailVerified)
        if(user?.emailVerified){
          clearInterval(interval)
          navigate('/tools')
        }
      })
      .catch((err) => {
        alert(err.message)
      })
    }, 1000)
  }, [navigate, user])

 
  const resendEmailVerification = () => {
    sendEmailVerification(auth.user)
    .then(() => {
      //setTimeActive(true)
    }).catch((err) => {
      alert(err.message)
    })
  }

  return (
    <>
    {/* <div className="float-left my-5 w-100 text-center">
       <LogoSvg className="mx-auto"/> 
    </div> */}
    <Card className="col-5 p-0 card my-5 mx-auto loginForm"> 
    <Card.Body>
    <div className="pt-4 pb-4 box text-center">
        <h2>Verify your email address</h2> 
      <div className="box mt-3 text-center"> 
        <p className="pb-3">
          We sent you a verification email. <br/>Please click on the link to access the app.  
          <strong>{user?.email}</strong>
        </p>     
        <p>If you have not received, please check your "Spam" folder.</p>
        <p>Verification link expired? <br/><Button className='mt-2' variant="outline-primary" size="sm" onClick={resendEmailVerification}>Resend verification email</Button></p>
        
        <p>
        Already have an account? <Link to="/login">Sign in</Link>
      </p> 
        
      </div>
    </div>
    </Card.Body>
    </Card>
    </>   
  )
}

export default VerifyEmail