import React from "react";  
import "./Loader.css"; 

const Loader = () => {  
  return (
    <>  
      <div className="loader"></div>
    </>
  );
};

export default Loader;