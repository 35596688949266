const CareersData = {  
resumeSummary : {
title:"Resume summary",
caption:"This AI template can help you write a resume summary that will get results.",
category:"career",
inputs :`Job title: [current job title]
Experience: [years of experience]
Skills: [add your skills]
Key achievements: [add your key achievements]
How you can help the organization: [Say how you want to help the employer achieve their goals]`
},
careerObjective : {
title:"Career objective",
caption:"This AI template can help you write a career objective that will get results.",
category:"career",
inputs :`Job Type: [eg: Marketing] 
Skills: [eg: Marketing, Communication, Business Strategy]`
}, 
coverLetter : {
title:"Cover letter",
caption:"This AI template can help you write a cover letter that will get results.",
category:"career",
inputs : 
`Job title: [job title]
Company name: [company name]
Company details: [about the company]
Skills: [relevant skills]
Relevant experience: [relevant experience]

Format:
Greetings
Introduction and explain why
Write that you are interested in the job
Write relevant experience and skills
Thank the reader`
},
teacherCoverLetter : {
title:"Teacher cover letter",
caption:"Help's you write a teacher cover letter that will impress the interviewr.",
category:"career",
inputs :`Your name: [your name]
Job title: [job title]
School name: [school name]
Your qualifications for the job: [your qualifications for the job]

Format:
Greetings
Introduce yourself and state your purpose for writing.
Describe your qualifications for the position.
Describe how you learned about this opportunity and why it appeals to you.
Finish on a formal note.`
}, 
customerServiceCoverLetter : {
title:"Customer service cover letter",
caption:"Help's you to write a customer service cover letter that will get results.",
category:"career",
inputs :`Your name: [your name]
Job title: [job title] 
Your relevant experience and skills: [your relevant experience and skills for the job]
Your qualifications for the job: [your qualifications for the job]

Format:
Greetings
Introduce yourself and state your purpose for writing.
Describe your qualifications for the position.
Explain how those skills will help you succeed in this role
Finish on a formal note.`
}, 
nurseCoverLetter : {
title:"Nurse cover letter",
caption:"This AI template can help you write a nurse cover letter that will get results.",
category:"career",
inputs :`Your name: [write your name]
Job title: [job title]
Hospital name: [Hospital name]
Your skills: [Your skills]
Your experience: [Your experience]
Your qualifications for the job: [your qualifications]

Format:
Greetings
Introduce yourself and state your purpose for writing.
Describe your experience and qualifications for this job.
Explain how you are fit for this role
Finish on a formal note.`
}, 
salesCoverLetter : {
title:"Sales cover letter",
caption:"This AI template can help you write a sales cover letter that will get results.",
category:"career",
inputs :`Your name: [your name]
Job title: [title of job]
Company name: [Company name]
Your skills: [list your skills]
Your experience: [years of experience] 

Structure:
Start with greetings
Introduce yourself and purpose for writing.
Describe your relevant skills and experience
Explain how you are fit for this role
Finish on a formal note.`
}
}
export default CareersData;