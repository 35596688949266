import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { UserAuthContextProvider } from "./context/UserAuthContext"; 
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/css/ad-design-system-react.css";

import App from "./App";

ReactDOM.render(
  <React.StrictMode> 
    <Router>
        <UserAuthContextProvider>  
          <App />
        </UserAuthContextProvider>
      </Router>
  </React.StrictMode>,
  document.getElementById("root")
);