import React, {useState, useEffect, useContext} from "react"; 
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert, Button, Card } from "react-bootstrap"; 
import GoogleButton from "react-google-button";
import { useUserAuth } from "../context/UserAuthContext";
import { AuthErrorCodes } from "@firebase/auth";
import { GoogleAdIcon, LogoSvg } from "../assets/svg/SvgIcons";
import { useParams } from "react-router-dom";
import './loginForm.css' 

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); 
  const params = useParams();

  const { logIn, googleSignIn, user } = useUserAuth();
  
  const navigate = useNavigate();

  useEffect(() => {   
     if(user && user.emailVerified){ 
        navigate("/stock-image"); 
     }
  }, [user])
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await logIn(email, password);
      //console.log(params)
      let redirectUrl = '/stock-image'
      if(params){ 
        if(params.type.length > 4){
          // redirectUrl = `/flexy-template/${params.type}`;
          redirectUrl = '/stock-image';
        } 
      }  
      navigate(redirectUrl);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn();
      //navigate("/stock-image");
      let redirectUrl = '/stock-image'
      if(params){ 
        if(params.type.length > 4){
          // redirectUrl = `/flexy-template/${params.type}`;
          redirectUrl = '/stock-image'
        } 
      } 
      navigate(redirectUrl);
    } catch (error) {
      //console.log(error.message);
    }
  };

  return (
    <>
    {/* <div className="float-left my-5 w-100 text-center">
       <LogoSvg className="mx-auto"/>
    </div> */}
   
    <Card className="col-4 p-0 card my-5 mx-auto loginForm"> 
    <Card.Body>
    <div className="p-4 box"> 
        <h2>Login / Signup</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder="Email address"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-4" controlId="formBasicPassword">
            <Form.Control
              type="password"
              placeholder="Password"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <div className="d-grid gap-2">
            <Button variant="primary" type="Submit">
              Login
            </Button>
          </div>
        </Form>
        <hr />
        <div>
          <Button className="w-100 googleSignin" onClick={handleGoogleSignIn}><GoogleAdIcon /> Login with Google </Button> 
        </div>
      </div>
      <div className="p-4 box text-center">
        <p><Link to="/reset">Forgot password</Link></p>
        Don't have an account? <Link to="/signup">Sign up</Link>
      </div>
    </Card.Body>
  </Card>

      
    </>
  );
};

export default Login;