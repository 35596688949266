import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert, Button, Card  } from "react-bootstrap"; 
import { useUserAuth } from "../context/UserAuthContext"; 
import './loginForm.css' 
import { LogoSvg } from "../assets/svg/SvgIcons";

const Reset = () => {
  const [email, setEmail] = useState(""); 
  const [error, setError] = useState(""); 
  const [alert, setAlert] = useState("");

  const { passwordReset } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await passwordReset(email);  
      setAlert("Password reset link sent!");
    } catch (err) {
      setError(err.message);
    }
  };
 

  return (
    <>
    {/* <div className="float-left my-5 w-100 text-center"> 
       <LogoSvg className="mx-auto"/>
    </div> */}

    <Card className="col-4 p-0 card my-5 mx-auto loginForm"> 
    <Card.Body>
    <div className="p-4 box">
        <h2>Reset Your Password</h2>
        <p>We'll send you an email to reset your password.</p>
        {error && <Alert variant="danger">{error}</Alert>} 
        {alert && <Alert variant="success">{alert}</Alert>} 
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-4" controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder="Email address"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>  
          <div className="d-grid gap-2">
            <Button variant="primary" type="Submit">
              Submit
            </Button>
          </div>
        </Form> 
      </div>
      <div className="p-4 text-center">
        <p><Link to="/login">Back to Sign in</Link></p> 
      </div>
      </Card.Body>
      </Card>
    </>
  );
};

export default Reset;